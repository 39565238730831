define('test-client/helpers/fixed', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.fixed = fixed;

  function fixed(params /*, hash*/) {
    if (!isNaN(parseFloat(params[0]))) {
      return params[0].toFixed(1);
    }
  }

  exports['default'] = Ember['default'].Helper.helper(fixed);

});