define('test-client/utils/constants', ['exports'], function (exports) {

	'use strict';

	var ABORT_FAST_FLAG_MAX_EXCEEDED = 0;
	var STOP_MAX_TIME = 10;
	var STOP_BROWSER_CLOSED = 11;

	exports.ABORT_FAST_FLAG_MAX_EXCEEDED = ABORT_FAST_FLAG_MAX_EXCEEDED;
	exports.STOP_MAX_TIME = STOP_MAX_TIME;
	exports.STOP_BROWSER_CLOSED = STOP_BROWSER_CLOSED;

});