define('test-client/locales/da/translations', ['exports', 'ember', 'test-client/locales/en/translations'], function (exports, Ember, en) {

  'use strict';

  exports['default'] = Ember['default'].merge(Ember['default'].copy(en['default']), {
    'button.start': 'Jeg er klar til at begynde',
    'button.startTest': 'Start opgaverne',
    'button.instructions': 'Start vejledningen',
    'button.understand': 'Tak, jeg har forstået det',
    'button.continue': 'Forsæt',
    'button.finished': 'Færdig',

    'alert.fullscreen': 'Din browser vil skifte til fuldskærmstilstand for at mindske forstyrrelser',
    'alert.requiredFields': 'Både køn og alder skal udfyldes',
    'alert.invalidAge': 'Alder (i år) skal være 3 eller derover.',
    'alert.selectEffort': 'Træk på skydeknappen for at angive, hvor hårdt du forsøgte.',

    'title.welcome': 'Velkommen',
    'title.demographics': 'Først lidt om dig selv.',
    'title.instructions': 'Eksempel 1',
    'title.reminders': 'Lad os komme i gang!',
    'title.feedback': 'Hvor hårdt forsøgte du?',
    'title.lifestyle': 'Flot arbejde med opgaverne!',
    'title.complete': 'Tak for din deltagelse!',

    'form.field.required': 'Nødvendig /Påkrævet',

    'form.label.gender': 'Køn',
    'form.label.age': 'Alder',
    'form.label.race': 'Etnisk baggrund',
    'form.label.education': 'Uddannelse',
    'form.label.didNotTry': 'Jeg forsøgte ikke',
    'form.label.didMyBest': 'Jeg gjorde mit bedste',
    'form.label.set': 'Parat',
    'form.label.score': 'Pointscore',
    'form.label.lifestyle.motivation': 'I livet betragter jeg mig selv:',
    'form.label.lifestyle.school': 'Generelt kan jeg lide skolen',
    'form.label.lifestyle.rest': 'Jeg føler mig udhvilet efter søvn:',
    'form.label.lifestyle.restMinor': 'I løbet af dagen føler jeg mig træt:',
    'form.label.lifestyle.fitness': 'Jeg er fysisk aktiv:',
    'form.label.lifestyle.handedness': 'Jeg bruger for det meste min:',
    'form.label.lifestyle.games': 'Jeg spiller computerspil:',
    'form.label.lifestyle.smoking': 'Jeg er en:',
    'form.label.lifestyle.alcohol': 'Jeg drikker alkohol:',
    'form.label.lifestyle.caffeine': 'Jeg drikker kaffe/the:',
    'form.label.lifestyle.control': 'I livet føler jeg at jeg har:',

    'form.choice.info.genderA': 'Mand',
    'form.choice.info.genderB': 'Kvinde',
    'form.choice.info.race0': 'Europæer',
    'form.choice.info.race1': 'Asiat',
    'form.choice.info.race2': 'Blandet etnisk baggrund',
    'form.choice.info.race3': 'Sort',
    'form.choice.info.race4': 'Hvid',
    'form.choice.info.race5': 'Anden',
    'form.choice.info.education0': 'Børnehaveklasse',
    'form.choice.info.education1': 'Børnehave',
    'form.choice.info.education2': '1st klasse',
    'form.choice.info.education3': '2 klasse',
    'form.choice.info.education4': '3 klasse',
    'form.choice.info.education5': '4 klasse',
    'form.choice.info.education6': '5 klasse',
    'form.choice.info.education7': '6 klasse',
    'form.choice.info.education8': '7 klasse',
    'form.choice.info.education9': '8 klasse',
    'form.choice.info.education10': '9 klasse',
    'form.choice.info.education11': '10 klasse',
    'form.choice.info.education12': '1G (gymnasium)',
    'form.choice.info.education13': '2G (gymnasium)',
    'form.choice.info.education14': 'Handelsskole',
    'form.choice.info.education15': 'Gymnasieluddannelse',
    'form.choice.info.education16': 'Diplomgrad (eksempel: laboratorie teknikker)',
    'form.choice.info.education17': 'Bachelorgrad',
    'form.choice.info.education18': 'Mastergrad',
    'form.choice.info.education19': 'Kandidatgrad (eksempel: læge, jurist, tandlæge)',
    'form.choice.info.education20': 'Doktorgrad',
    'form.choice.lifestyle.motivationA': 'Ikke særligt motiveret',
    'form.choice.lifestyle.motivationB': 'I nogen grad motiveret',
    'form.choice.lifestyle.motivationC': 'Meget motiveret',
    'form.choice.lifestyle.schoolA': 'Overhovedet ikke',
    'form.choice.lifestyle.schoolB': 'En lille smule',
    'form.choice.lifestyle.schoolC': 'I høj grad',
    'form.choice.lifestyle.restA': 'Aldrig',
    'form.choice.lifestyle.restB': 'Nogengange',
    'form.choice.lifestyle.restC': 'Altid',
    'form.choice.lifestyle.smokingA': 'Ikke-ryger',
    'form.choice.lifestyle.smokingB': 'Tidligere ryger',
    'form.choice.lifestyle.smokingC': 'Ryger',
    'form.choice.lifestyle.alcoholA': 'Aldrig',
    'form.choice.lifestyle.alcoholB': '1-4 drinks per uge',
    'form.choice.lifestyle.alcoholC': '5 eller flere drinks per uge',
    'form.choice.lifestyle.caffeineA': 'Aldrig',
    'form.choice.lifestyle.caffeineB': 'En gang dagligt',
    'form.choice.lifestyle.caffeineC': 'Mere end en gang dagligt',
    'form.choice.lifestyle.controlA': 'Meget lidt kontrol',
    'form.choice.lifestyle.controlB': 'Nogen kontrol',
    'form.choice.lifestyle.controlC': 'Meget god kontrol',
    'form.choice.lifestyle.fitnessA': 'Stillesiddende (lidt daglig aktivitet)',
    'form.choice.lifestyle.fitnessB': 'Aktiv (moderat daglige gåture)',
    'form.choice.lifestyle.fitnessC': 'Athletic (træner regelmæssigt)',
    'form.choice.lifestyle.handednessA': 'Højre håndet',
    'form.choice.lifestyle.handednessB': 'Venstre håndet',
    'form.choice.lifestyle.handednessC': 'Anvender begge hænder lige hyppigt',
    'form.choice.lifestyle.gamesA': 'Aldrig',
    'form.choice.lifestyle.gamesB': 'Nogengange',
    'form.choice.lifestyle.gamesC': 'De fleste dage',

    'copy.reminders.nextPage': 'På næste side begynder opgaverne.',
    'copy.reminders.doYourBest': 'Prøv at gøre dit allerbedste.',
    'copy.reminders.refresh': 'Du må ikke opdatere siden.',
    'copy.reminders.distractions': 'Sluk venligst din mobiltelefon eller andre ting der kan forstyrrer dig inden du starter.',
    'copy.lifestyle.instructions': 'Brug venligst et øjeblik på at afrunde inden du afslutter.',
    'copy.complete.noURL': 'Vi er helt færdige her. Tak, og ha\' en god dag!',
    'copy.complete.URL': 'Du vil blive ført tilbage til',
    'copy.complete.URLTime': 'om 10 sekunder.'
  });

});