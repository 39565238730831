define('test-client/locales/fr/translations', ['exports', 'ember', 'test-client/locales/en/translations'], function (exports, Ember, en) {

  'use strict';

  exports['default'] = Ember['default'].merge(Ember['default'].copy(en['default']), {
    'button.start': 'Je suis prêt-e à commencer',
    'button.startTest': 'Débuter les puzzles',
    'button.instructions': 'Voir les instructions',
    'button.understand': 'J\'ai compris',
    'button.continue': 'Continuer',
    'button.finished': 'Terminé',

    'alert.fullscreen': 'Votre navigateur passera en mode plein écran afin de minimiser les eléments pertubateurs.',
    'alert.requiredFields': 'Le sexe et l\'âge doivent être complétés.',
    'alert.invalidAge': 'L’âge (en années) doit être supérieur ou égal à 3.',
    'alert.selectEffort': 'Faites glisser le curseur pour indiquer à quel point vous vous êtes impliqué dans cet exercice.',

    'title.welcome': 'Bienvenue',
    'title.demographics': 'Quelques questions vous concernant',
    'title.instructions': 'Exemple {{index}}',
    'title.reminders': 'Commençons!',
    'title.feedback': 'A quel point vous êtes-vous impliqué dans cet exercice?',
    'title.lifestyle': 'Bon travail sur ces puzzles!',
    'title.complete': 'Merci pour votre participation!',

    'form.field.required': 'requis',

    'form.label.gender': 'Genre',
    'form.label.age': 'Âge',
    'form.label.race': 'Ethnie',
    'form.label.education': 'Scolarité/Formation',
    'form.label.didNotTry': 'Je ne me suis pas donné de peine',
    'form.label.didMyBest': 'J’ai fait du mieux possible',
    'form.label.set': 'série',
    'form.label.score': 'résultat',
    'form.label.lifestyle.motivation': 'Dans la vie, je me considère comme:',
    'form.label.lifestyle.school': 'En général, j’aime l\'école:',
    'form.label.lifestyle.rest': 'Je me sens reposé-e de mon sommeil:',
    'form.label.lifestyle.restMinor': 'Pendant la journée, je me sens fatigué-e:',
    'form.label.lifestyle.fitness': 'Je suis physiquement:',
    'form.label.lifestyle.handedness': 'J’utilise surtout:',
    'form.label.lifestyle.games': 'Je joue à des jeux sur ordinateur:',
    'form.label.lifestyle.smoking': 'Je suis:',
    'form.label.lifestyle.alcohol': 'Je bois de l\'alcool:',
    'form.label.lifestyle.caffeine': 'Je bois du café/thé:',
    'form.label.lifestyle.control': 'Dans la vie, j’ai l’impression que j’ai:',

    'form.choice.info.genderA': 'Homme',
    'form.choice.info.genderB': 'Femme',
    'form.choice.info.race0': 'Indien d\'Amérique',
    'form.choice.info.race1': 'Asiatique',
    'form.choice.info.race2': 'Métisse',
    'form.choice.info.race3': 'Africain-e',
    'form.choice.info.race4': 'Caucasien-ne',
    'form.choice.info.race5': 'Autre',
    'form.choice.info.education0': 'Préscolaire',
    'form.choice.info.education1': 'Maternelle ',
    'form.choice.info.education2': '1ère année CP',
    'form.choice.info.education3': '2ème année CE1',
    'form.choice.info.education4': '3ème année CE2',
    'form.choice.info.education5': '4ème année CM1',
    'form.choice.info.education6': '5ème année CM2',
    'form.choice.info.education7': '6ème année, 6éme',
    'form.choice.info.education8': '7ème année, 5éme',
    'form.choice.info.education9': 'la 8ème année, 4éme',
    'form.choice.info.education10': '9ème année, 3éme',
    'form.choice.info.education11': '10ème année, 2nde',
    'form.choice.info.education12': '11ème année, 1ére',
    'form.choice.info.education13': 'Terminale',
    'form.choice.info.education14': 'Lycée de Metiers',
    'form.choice.info.education15': 'Etudes universitaires partielles',
    'form.choice.info.education16': 'DEUG, BTS, DUT',
    'form.choice.info.education17': 'Licence',
    'form.choice.info.education18': 'Maîtrise',
    'form.choice.info.education19': 'Doctorat professionnel',
    'form.choice.info.education20': 'Doctorat',
    'form.choice.lifestyle.motivationA': 'Pas très motivé-e',
    'form.choice.lifestyle.motivationB': 'Un peu motivé-e',
    'form.choice.lifestyle.motivationC': 'Très motivé-e',
    'form.choice.lifestyle.schoolA': 'Pas du tout',
    'form.choice.lifestyle.schoolB': 'un peu',
    'form.choice.lifestyle.schoolC': 'beaucoup',
    'form.choice.lifestyle.restA': 'Jamais',
    'form.choice.lifestyle.restB': 'Parfois',
    'form.choice.lifestyle.restC': 'Toujours',
    'form.choice.lifestyle.smokingA': 'Non-fumeur -se',
    'form.choice.lifestyle.smokingB': 'Ancien fumeur-se',
    'form.choice.lifestyle.smokingC': 'Fumeur-se',
    'form.choice.lifestyle.alcoholA': 'Jamais',
    'form.choice.lifestyle.alcoholB': '1-4 verres par semaine',
    'form.choice.lifestyle.alcoholC': '5 verres ou plus par semaine',
    'form.choice.lifestyle.caffeineA': 'Jamais',
    'form.choice.lifestyle.caffeineB': 'Une fois par jour',
    'form.choice.lifestyle.caffeineC': 'Plus d\'une fois par jour',
    'form.choice.lifestyle.controlA': 'Très peu de contrôle',
    'form.choice.lifestyle.controlB': 'Un peu de contrôle',
    'form.choice.lifestyle.controlC': 'Un très bon contrôle',
    'form.choice.lifestyle.fitnessA': 'sédentaire (peu d\'activité physique par jour)',
    'form.choice.lifestyle.fitnessB': 'actif (marche quotidienne modérée)',
    'form.choice.lifestyle.fitnessC': 'athlétique (entraînement régulière)',
    'form.choice.lifestyle.handednessA': 'la main droite',
    'form.choice.lifestyle.handednessB': 'la main gauche',
    'form.choice.lifestyle.handednessC': 'les deux mains',
    'form.choice.lifestyle.gamesA': 'Jamais',
    'form.choice.lifestyle.gamesB': 'Parfois',
    'form.choice.lifestyle.gamesC': 'La plupart du temps',
    'copy.reminders.nextPage': 'Sur la page suivante, vous allez débuter les puzzles.',
    'copy.reminders.doYourBest': 'Faites de votre mieux.',
    'copy.reminders.refresh': 'N’actualisez pas la page',
    'copy.reminders.distractions': 'Veuillez s’il-vous-plaît éteindre votre téléphone portable et limiter toute source de distraction avant de commencer.',
    'copy.lifestyle.instructions': 'Veuillez s’il-vous-plaît prendre un moment pour vérifier et terminer',
    'copy.complete.noURL': 'Vous avez maintenant tout complété. Merci, et bonne journée!',
    'copy.complete.URL': 'Vous serez redirigé vers',
    'copy.complete.URLTime': 'dans 10 secondes.'
  });

});