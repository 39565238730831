define('test-client/pods/site/inquire/route', ['exports', 'ember', 'ic-ajax', 'test-client/config/environment'], function (exports, Ember, ajax, config) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    actions: {
      submit: function submit(name, organization, email, phone, description) {
        var _this = this;

        if (!name || !organization || !email || !phone || !description) {
          return this.controller.set('error', true);
        }

        var parts = [config['default'].host, config['default'].namespace];
        ajax['default'](parts.reduce(function (a, b) {
          return a + (b ? b + '/' : '');
        }, '') + 'inquire', {
          type: 'POST',
          data: JSON.stringify({
            data: {
              type: 'inquire',
              attributes: {
                name: name,
                organization: organization,
                email: email,
                phone: phone,
                description: description
              }
            }
          }),
          contentType: 'application/vnd.api+json',
          dataType: 'json'
        }).then(function () {
          var c = _this.controller;
          c.set('success', true);
          c.set('error');
          c.set('name');
          c.set('organization');
          c.set('email');
          c.set('phone');
          c.set('description');
        })['catch'](function () {
          _this.controller.set('error', true);
        });
      }
    }
  });

});