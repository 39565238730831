define('test-client/pods/site/index/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('storage', this.storage);
    }
  });

});