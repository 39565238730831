define('test-client/router', ['exports', 'ember', 'test-client/config/environment'], function (exports, Ember, config) {

  'use strict';

  var Router = Ember['default'].Router.extend({
    location: config['default'].locationType
  });

  Router.map(function () {
    this.route('site', { path: '/' }, function () {
      this.route('signin', { path: '/sign-in' });
      this.route('signout', { path: '/sign-out' });
      this.route('inquire', { path: '/account-inquiry' });
      this.route('reset', { path: '/reset-password/:token' });
      this.route('invitation', { path: '/invitation/:invitation' });
      this.route('account', function () {
        this.route('project', { path: '/project/:id' }, function () {
          this.route('participants', function () {
            this.route('detail', { path: '/:participant_id' });
            this.route('definitions', { path: '/definitions' });
          });
        });
        this.route('settings');
        this.route('legal');
        this.route('nda');
        this.route('training');
      });
    });
    this.route('testing', { path: '/app/:id' }, function () {
      this.route('info');
      this.route('instructions');
      this.route('reminders');
      this.route('puzzles');
      this.route('lifestyle');
      this.route('complete');
    });
    this.route('404', { path: '*:catchAll' });
  });

  exports['default'] = Router;

});