define('test-client/models/instruction', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var attr = DS['default'].attr;
  exports['default'] = DS['default'].Model.extend({
    caption: attr(),
    file: attr(),
    project: DS['default'].belongsTo('project', { async: false })
  });

});