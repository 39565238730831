define('test-client/pods/testing/lifestyle/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model() {
      return this.modelFor('testing');
    },
    actions: {
      submitLifestyle: function submitLifestyle(model) {
        var _this = this;

        model.save().then(function () {
          _this.transitionTo('testing.complete');
        })['catch'](function () {
          _this.set('error', 'Your response could not be saved. Please try again.');
        });
      }
    }
  });

});