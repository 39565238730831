define('test-client/adapters/application', ['exports', 'ember', 'ember-data', 'test-client/config/environment'], function (exports, Ember, DS, config) {

  'use strict';

  exports['default'] = DS['default'].JSONAPIAdapter.extend({
    storage: Ember['default'].inject.service(),
    namespace: config['default'].namespace,
    host: config['default'].host,
    coalesceFindRequests: true,
    shouldReloadAll: function shouldReloadAll() {
      return true;
    },
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return false;
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      // this is kind of terrible but Mirage can't handle PATCH so we use PUT but only during integration tests
      if (!Ember['default'].testing) {
        return this._super.apply(this, arguments);
      }

      var data = {};
      var serializer = store.serializerFor(type.modelName);

      serializer.serializeIntoHash(data, type, snapshot, { includeId: true });

      var id = snapshot.id;
      var url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');

      return this.ajax(url, 'PUT', { data: data });
    },
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      var url = this._super.apply(this, arguments);
      var query = Ember['default'].get(snapshot, 'adapterOptions.query');
      if (query) {
        url += '?' + Ember['default'].$.param(query); // assumes no query params are present already
      }
      return url;
    },
    headers: (function () {
      if (this.get('session.scheme') === 'token') {
        return {
          'Authorization': 'Token ' + this.get('storage.token')
        };
      }

      return {
        'X-Participant': this.get('session.hash')
      };
    }).property('session.hash')
  });

});