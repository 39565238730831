define('test-client/pods/testing/index/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    actions: {
      toInfo: function toInfo() {
        this.transitionTo('testing.info');
      }
    }
  });

});