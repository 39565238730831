define('test-client/pods/site/invitation/route', ['exports', 'ember', 'ic-ajax', 'test-client/config/environment'], function (exports, Ember, ajax, config) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    beforeModel: function beforeModel() {
      this.set('storage.token');
      this.set('storage.userID');
    },
    model: function model(params) {
      return params.invitation;
    },
    actions: {
      submit: function submit(invitation, password, password2) {
        var _this = this;

        if (!password || password !== password2) {
          return this.controller.set('error', true);
        }

        var parts = [config['default'].host, config['default'].namespace];
        ajax['default'](parts.reduce(function (a, b) {
          return a + (b ? b + '/' : '');
        }, '') + 'set-password', {
          type: 'POST',
          data: JSON.stringify({
            data: {
              type: 'auth',
              attributes: {
                invitation: invitation,
                password: password
              }
            }
          }),
          contentType: 'application/vnd.api+json',
          dataType: 'json'
        }).then(function () {
          _this.controller.set('error');
          _this.transitionTo('site.signin');
        })['catch'](function () {
          _this.controller.set('error', true);
        });
      }
    }
  });

});