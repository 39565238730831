define('test-client/pods/site/invitation/controller', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    resize: Ember['default'].inject.service(),
    height: (function () {
      return this.get('resize.height') - 210;
    }).property('resize.height')
  });

});