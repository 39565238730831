define('test-client/services/resize', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Service.extend({
    width: (function () {
      if (!this.bound) {
        this.bindResizing();
      }
      return this.get('_width');
    }).property('_width'),
    height: (function () {
      if (!this.bound) {
        this.bindResizing();
      }
      return this.get('_height');
    }).property('_height'),
    bindResizing: function bindResizing() {
      var _this = this;

      this.bound = true;
      var onResize = function onResize() {
        return Ember['default'].run.debounce(function () {
          _this.set('_height', window.$(window).height());
          _this.set('_width', window.$(window).width());
        }, 100);
      };
      window.$(window).bind('resize', onResize);
      onResize();
    },
    unbindResizing: (function () {
      window.$(window).unbind('resize');
    }).on('willDestroy')
  });

});