define('test-client/pods/testing/info/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    setupController: function setupController(controller, model) {
      controller.set('model', model);
    },
    actions: {
      validateAge: function validateAge(age) {
        if (isNaN(age) || age < 3 || age > 108) {
          this.controller.set('ageError', true);
        } else {
          this.controller.set('ageError');
        }
      },
      saveInfo: function saveInfo() {
        var _this = this;

        var _controller = this.controller;
        var male = _controller.male;
        var age = _controller.age;
        var race = _controller.race;
        var education = _controller.education;

        if (typeof male === 'undefined' || typeof age === 'undefined' || isNaN(age) || age < 3 || age > 108) {
          this.controller.set('incompleteError', true);
          return;
        }

        //requestFullscreen();
        // persist to server
        var participant = this.modelFor('testing');

        this.transitionTo('testing.instructions');
        if (!participant) {
          Raven.captureMessage('modelFor(\'testing\') was undefined in /info route', {
            level: 'info'
          });
          this.transitionTo('testing.complete');
        }

        participant.setProperties({
          male: male, age: age, race: race, education: education
        });
        participant.save().then(function () {
          _this.transitionTo('testing.instructions');
        });
      }
    }
  });

});