define('test-client/pods/components/api-key/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    tagName: 'tr',
    store: Ember['default'].inject.service(),
    actions: {
      deleteKey: function deleteKey() {
        var key = this.get('key');
        if (window.confirm('This action cannot be undone. Are you sure you wish to delete this API key?')) {
          key.deleteRecord();
          key.save();
        }
      }
    }
  });

});