define('test-client/locales/ru/translations', ['exports', 'ember', 'test-client/locales/en/translations'], function (exports, Ember, en) {

  'use strict';

  exports['default'] = Ember['default'].merge(Ember['default'].copy(en['default']), {
    'button.start': 'Я готов/а начать.',
    'button.startTest': 'Начните загадки.',
    'button.instructions': 'Начните инструкции.',
    'button.understand': 'Понятно.',
    'button.continue': 'Продолжите.',
    'button.finished': 'Я закончил/а',

    'alert.fullscreen': 'Ваш браузер переключится на полноэкранный режим, чтобы минимизировать отвлекающие факторы.',
    'alert.requiredFields': 'И пол, и возраст - обязательно заполнить.',
    'alert.invalidAge': 'Возраст (полных лет): должна быть цифра 3 или больше.',
    'alert.selectEffort': 'Выберите число, чтобы отметить, насколько сильно Вы старались.',

    'title.welcome': 'Добро пожаловать!',
    'title.demographics': 'Сначала немного о Вас.',
    'title.instructions': 'Пример',
    'title.reminders': 'Давайте начнём!',
    'title.feedback': 'Насколько сильно Вы старались?',
    'title.lifestyle': 'Очень хорошо!',
    'title.complete': 'Спасибо за участие!',

    'form.field.required': 'Обязательно',

    'form.label.gender': 'Пол',
    'form.label.age': 'Возраст',
    'form.label.race': 'Раса',
    'form.label.education': 'Образование',
    'form.label.didNotTry': 'Я не старался/ась.',
    'form.label.didMyBest': 'Я очень старался/ась.',
    'form.label.set': 'Серия',
    'form.label.score': 'балл/результат',
    'form.label.lifestyle.motivation': 'В жизни я считаю, что',
    'form.label.lifestyle.school': 'В общем, мне нравится школа/университет',
    'form.label.lifestyle.rest': 'Я чувствую себя отдохнувшим после сна -',
    'form.label.lifestyle.restMinor': 'В течение дня я чувствую себя усталым',
    'form.label.lifestyle.fitness': 'Мой образ жизни',
    'form.label.lifestyle.handedness': 'Я использую главным образом',
    'form.label.lifestyle.games': 'Я играю в компьютерные игры',
    'form.label.lifestyle.smoking': 'Я -',
    'form.label.lifestyle.alcohol': 'Я пью алкоголь',
    'form.label.lifestyle.caffeine': 'Я пью кофе/чай',
    'form.label.lifestyle.control': 'Я чувствую, что контролирую свою жизнь',

    'form.choice.info.genderA': 'Я -мужчина.',
    'form.choice.info.genderB': 'Я -женщина.',
    'form.choice.info.race0': 'Я -индеец.',
    'form.choice.info.race1': 'Я -азиат.',
    'form.choice.info.race2': 'Я -смешанной расы.',
    'form.choice.info.race3': 'Я -темнокожий.',
    'form.choice.info.race4': 'Я -белокожий.',
    'form.choice.info.race5': 'Я -другое.',
    'form.choice.info.education0': 'Дошкольный',
    'form.choice.info.education1': 'Детский сад',
    'form.choice.info.education2': '1-й класс',
    'form.choice.info.education3': '2-й класс',
    'form.choice.info.education4': '3-й класс',
    'form.choice.info.education5': '4-й класс',
    'form.choice.info.education6': '5-й класс',
    'form.choice.info.education7': '6-й класс',
    'form.choice.info.education8': '7-й класс',
    'form.choice.info.education9': '8-й класс',
    'form.choice.info.education10': '9-й класс',
    'form.choice.info.education11': '10-й класс',
    'form.choice.info.education12': '11-й класс',
    'form.choice.info.education13': '12-й класс',
    'form.choice.info.education14': 'Профецессионально-техническое училище',
    'form.choice.info.education15': 'Не закончил колледж',
    'form.choice.info.education16': 'Два года бакалавра (пример: медицинская технология, HVAC)',
    'form.choice.info.education17': 'Степень бакалавра',
    'form.choice.info.education18': 'Степень магистра',
    'form.choice.info.education19': 'Профессиональная степень (пример: MD, JD, DDS)',
    'form.choice.info.education20': 'Докторская степень      ',
    'form.choice.lifestyle.motivationA': 'у меня нет чёткой мотивации.',
    'form.choice.lifestyle.motivationB': 'я иногда знаю свою мотивацию.',
    'form.choice.lifestyle.motivationC': 'я всегда знаю свою мотивацию.',
    'form.choice.lifestyle.schoolA': 'не совсем.',
    'form.choice.lifestyle.schoolB': 'немного.',
    'form.choice.lifestyle.schoolC': 'очень.',
    'form.choice.lifestyle.restA': 'никогда.',
    'form.choice.lifestyle.restB': 'иногда.',
    'form.choice.lifestyle.restC': 'всегда.',
    'form.choice.lifestyle.smokingA': 'не курильщик/не курильщица.',
    'form.choice.lifestyle.smokingB': 'бывший курильщик/бывшая курильщица.',
    'form.choice.lifestyle.smokingC': 'курильщик/курильщица.',
    'form.choice.lifestyle.alcoholA': 'никогда.',
    'form.choice.lifestyle.alcoholB': 'один - четыре раза в неделю.',
    'form.choice.lifestyle.alcoholC': 'пять или больше раз в неделю.',
    'form.choice.lifestyle.caffeineA': 'никогда.',
    'form.choice.lifestyle.caffeineB': 'раз в день.',
    'form.choice.lifestyle.caffeineC': 'больше чем раз в день.',
    'form.choice.lifestyle.controlA': 'очень мало.',
    'form.choice.lifestyle.controlB': 'частично.',
    'form.choice.lifestyle.controlC': 'полностью.',
    'form.choice.lifestyle.fitnessA': 'сидячий (мало физической активности).',
    'form.choice.lifestyle.fitnessB': 'активный (умеренные ежедневные прогулки)',
    'form.choice.lifestyle.fitnessC': 'занимаюсь спортом очень часто.',
    'form.choice.lifestyle.handednessA': 'правую руку.',
    'form.choice.lifestyle.handednessB': 'левую руку.',
    'form.choice.lifestyle.handednessC': 'обе руки.',
    'form.choice.lifestyle.gamesA': 'никогда.',
    'form.choice.lifestyle.gamesB': 'иногда.',
    'form.choice.lifestyle.gamesC': 'почти каждый день.',

    'copy.reminders.nextPage': 'На следующей странице Вы начнёте решать загадки.',
    'copy.reminders.doYourBest': 'Постарайтесь ответить как можно лучше.',
    'copy.reminders.refresh': 'Не обновляйте страницу.',
    'copy.reminders.distractions': 'Пожалуйста, выключите свой сотовый телефон -устраните все, что Вас отвлекает, перед началом.',
    'copy.lifestyle.instructions': 'Пожалуйста, закончите задание.',
    'copy.complete.noURL': 'Мы закончили.  Спасибо и всего доброго!',
    'copy.complete.URL': 'Вы будете перенаправлены назад к',
    'copy.complete.URLTime': 'через 10 секунд.'
  });

});