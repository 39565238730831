define('test-client/serializers/application', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].JSONAPISerializer.extend({
    payloadKeyFromModelName: function payloadKeyFromModelName(modelName) {
      // don't pluralize
      return modelName;
    }
  });

});