define('test-client/locales/de/translations', ['exports', 'ember', 'test-client/locales/en/translations'], function (exports, Ember, en) {

  'use strict';

  exports['default'] = Ember['default'].merge(Ember['default'].copy(en['default']), {
    'button.start': 'Es kann losgehen',
    'button.startTest': 'Starte die Aufgaben',
    'button.instructions': 'Weiter zur Anleitung',
    'button.understand': 'Verstanden',
    'button.continue': 'Weiter',
    'button.finished': 'Fertig',

    'alert.fullscreen': 'Der Browser wird nun in den Vollbildmodus wechseln, damit du nicht abgelenkt werden kannst.',
    'alert.requiredFields': 'Geschlecht und Alter sind Pflichtfelder.',
    'alert.invalidAge': 'Alter (in Jahren) muss eine Zahl größer als 3 sein',
    'alert.selectEffort': 'Ziehe an dem Regler um anzugeben, wie sehr du dich bemüht hast.',

    'title.welcome': 'Willkommen',
    'title.demographics': 'Zuerst ein paar Dinge über dich.',
    'title.instructions': 'Beispiel 1',
    'title.reminders': 'Lass uns beginnen!',
    'title.feedback': 'Wie sehr hast du dich bemüht?',
    'title.lifestyle': 'Gute Arbeit!',
    'title.complete': 'Vielen Dank für deine Teilnahme!',

    'form.field.required': 'Pflichtfeld',

    'form.label.gender': 'Geschlecht',
    'form.label.age': 'Alter',
    'form.label.race': 'Ethnische Herkunft',
    'form.label.education': 'Bildung',
    'form.label.didNotTry': 'Ich habe es nicht versucht',
    'form.label.didMyBest': 'Ich habe mein Bestes gegeben',
    'form.label.set': 'Aufgabengruppe',
    'form.label.score': 'Punktestand',
    'form.label.lifestyle.motivation': 'Im Leben halte ich mich für:',
    'form.label.lifestyle.school': 'Die Schule mag ich im Allgemeinen:',
    'form.label.lifestyle.rest': 'Wie häufig fühlst du dich nach dem Schlafen ausgeruht?',
    'form.label.lifestyle.restMinor': 'Wie häufig fühlst du dich im Laufe des Tages müde?',
    'form.label.lifestyle.fitness': 'Wie körperlich aktiv bist du täglich?',
    'form.label.lifestyle.handedness': 'Ich benutze meistens meine: ',
    'form.label.lifestyle.games': 'Ich spiele Computerspiele:',
    'form.label.lifestyle.smoking': 'Ich bin ein:',
    'form.label.lifestyle.alcohol': 'Wie häufig trinkst du Alkohol?',
    'form.label.lifestyle.caffeine': 'Wie häufig trinkst du Kaffee/Tee?',
    'form.label.lifestyle.control': 'Über mein Leben habe ich:',

    'form.choice.info.genderA': 'Männlich',
    'form.choice.info.genderB': 'Weiblich',
    'form.choice.info.race0': 'Amerikanisch-indianisch',
    'form.choice.info.race1': 'Asiatisch',
    'form.choice.info.race2': 'Multi-ethnisch',
    'form.choice.info.race3': 'Schwarz',
    'form.choice.info.race4': 'Weiß',
    'form.choice.info.race5': 'Sonstige',
    'form.choice.info.education0': 'Vorschule',
    'form.choice.info.education1': 'Kindergarten',
    'form.choice.info.education2': '1. Klasse',
    'form.choice.info.education3': '2. Klasse',
    'form.choice.info.education4': '3. Klasse',
    'form.choice.info.education5': '4. Klasse ',
    'form.choice.info.education6': '5. Klasse',
    'form.choice.info.education7': '6. Klasse',
    'form.choice.info.education8': '7. Klasse',
    'form.choice.info.education9': '8. Klasse',
    'form.choice.info.education10': '9. Klasse',
    'form.choice.info.education11': '10. Klasse',
    'form.choice.info.education12': '11. Klasse',
    'form.choice.info.education13': '12. Klasse',
    'form.choice.info.education14': 'Fachhochschulreife',
    'form.choice.info.education15': 'Abitur',
    'form.choice.info.education16': 'Berufsabschluss mit Berufsakademie/Fachschule',
    'form.choice.info.education17': 'Vordiplom bzw. Bachelor-Abschluss',
    'form.choice.info.education18': 'Diplom bzw. Master-Abschluss ',
    'form.choice.info.education19': 'Staatsexamen',
    'form.choice.info.education20': 'Promotion',
    'form.choice.lifestyle.motivationA': 'Wenig motiviert',
    'form.choice.lifestyle.motivationB': 'Etwas motiviert',
    'form.choice.lifestyle.motivationC': 'Sehr motiviert',
    'form.choice.lifestyle.schoolA': 'Überhaupt nicht',
    'form.choice.lifestyle.schoolB': 'Ein Bisschen',
    'form.choice.lifestyle.schoolC': 'Sehr',
    'form.choice.lifestyle.restA': 'Nie',
    'form.choice.lifestyle.restB': 'Manchmal',
    'form.choice.lifestyle.restC': 'Immer',
    'form.choice.lifestyle.smokingA': 'Nichtraucher',
    'form.choice.lifestyle.smokingB': 'ehemaliger Raucher',
    'form.choice.lifestyle.smokingC': 'Raucher',
    'form.choice.lifestyle.alcoholA': 'Nie',
    'form.choice.lifestyle.alcoholB': '1 - 4 alkoholische Getränke pro Woche',
    'form.choice.lifestyle.alcoholC': '5 oder mehr alkoholische Getränke pro Woche',
    'form.choice.lifestyle.caffeineA': 'Nie',
    'form.choice.lifestyle.caffeineB': 'Einmal täglich',
    'form.choice.lifestyle.caffeineC': 'Mehr als einmal pro Tag',
    'form.choice.lifestyle.controlA': 'Sehr wenig Kontrolle',
    'form.choice.lifestyle.controlB': 'Eine gewisse Kontrolle',
    'form.choice.lifestyle.controlC': 'Sehr gute Kontrolle',
    'form.choice.lifestyle.fitnessA': 'Meist sitzend (wenig körperliche Betätigung)',
    'form.choice.lifestyle.fitnessB': 'Aktiv (täglich zu Fuß unterwegs)',
    'form.choice.lifestyle.fitnessC': 'Sportlich (regelmäßiges Training)',
    'form.choice.lifestyle.handednessA': 'Rechte Hand',
    'form.choice.lifestyle.handednessB': 'Linke Hand',
    'form.choice.lifestyle.handednessC': 'Beide Hände',
    'form.choice.lifestyle.gamesA': 'Nie',
    'form.choice.lifestyle.gamesB': 'Manchmal',
    'form.choice.lifestyle.gamesC': 'Meistens',

    'copy.reminders.nextPage': 'Auf der nächsten Seite werden die Aufgaben beginnen.',
    'copy.reminders.doYourBest': 'Bitte versuche die Aufgaben so gut wie möglich zu lösen.',
    'copy.reminders.refresh': 'Diese Seite nicht aktualisieren.',
    'copy.reminders.distractions': 'Bitte schalte dein Handy aus, bevor du anfängst.',
    'copy.lifestyle.instructions': 'Bitte nimm dir einen Moment Zeit, um die Aufgaben zu Ende zu bringen.',
    'copy.complete.noURL': 'Das war\'s. Vielen Dank für die Teilnahme!',
    'copy.complete.URL': 'Du wirst zurück geleitet',
    'copy.complete.URLTime': 'in 10 Sekunden'
  });

});