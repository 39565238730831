define('test-client/pods/components/network-message/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    didInsertElement: function didInsertElement() {
      Raven.captureMessage('Participant was shown the "slow network" notification', {
        level: 'info'
      });
    },
    click: function click() {
      this.set('shown');
      Raven.captureMessage('Participant dismissed "slow network" notice by click', {
        level: 'info'
      });
    }
  });

});