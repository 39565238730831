define('test-client/initializers/session', ['exports'], function (exports) {

  'use strict';

  exports.initialize = initialize;

  function initialize(container, application) {
    application.inject('route', 'session', 'service:session');
    application.inject('adapter', 'session', 'service:session');
  }

  exports['default'] = {
    name: 'session',
    initialize: initialize
  };

});