define('test-client/locales/fr-ch/translations', ['exports'], function (exports) {

  'use strict';


  exports['default'] = {
    'form.choice.info.education0': 'préscolaire (3 à 4 ans)',
    'form.choice.info.education1': 'école enfantine (4 à 6 ans)',
    'form.choice.info.education2': '1ère année école primaire (6-7)',
    'form.choice.info.education3': '2ème année école primaire (7-8)',
    'form.choice.info.education4': '3ème année école primaire (8-9)',
    'form.choice.info.education5': '4ème année école primaire (9-10)',
    'form.choice.info.education6': '5ème année école primaire (10-11)',
    'form.choice.info.education7': '6ème année, école primaire (11-12)',
    'form.choice.info.education8': 'école secondaire I (12-13)',
    'form.choice.info.education9': 'école secondaire I (13-14)',
    'form.choice.info.education10': 'école secondaire I (14-15)',
    'form.choice.info.education11': 'école secondaire II (15-16)',
    'form.choice.info.education12': 'école secondaire II (16-17)',
    'form.choice.info.education13': 'école secondaire II (17-18)',
    'form.choice.info.education14': 'école superiores',
    'form.choice.info.education15': 'Etudes universitaires partielles',
    'form.choice.info.education16': 'Bachelor/Master Haute Ecoles Specialisées (HES)',
    'form.choice.info.education17': 'Bachelor',
    'form.choice.info.education18': 'Master',
    'form.choice.info.education19': 'Doctorat professionnel',
    'form.choice.info.education20': 'Doctorat'
  };

});