define('test-client/pods/testing/puzzles/template', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 5,
            "column": 0
          }
        },
        "moduleName": "test-client/pods/testing/puzzles/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","puzzle-manager-wrapper");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
        return morphs;
      },
      statements: [
        ["inline","puzzle-manager",[],["complete","complete","lifestyle","lifestyle","item",["subexpr","@mut",[["get","model",["loc",[null,[3,66],[3,71]]]]],[],[]],"participant",["subexpr","@mut",[["get","participant",["loc",[null,[3,84],[3,95]]]]],[],[]],"store",["subexpr","@mut",[["get","store",["loc",[null,[3,102],[3,107]]]]],[],[]]],["loc",[null,[3,2],[3,109]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});