define('test-client/pods/site/signout/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model() {
      Raven.setUserContext();
      this.storage.set('token');
      this.storage.set('userID');
      this.storage.set('legal');
      window.location = '/';
    }
  });

});