define('test-client/pods/site/account/nda/template', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 6,
              "column": 0
            }
          },
          "moduleName": "test-client/pods/site/account/nda/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","md-toolbar-tools");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","md-breadcrumb-page");
          var el3 = dom.createTextNode("Non Disclosure Agreement");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 0
            },
            "end": {
              "line": 23,
              "column": 0
            }
          },
          "moduleName": "test-client/pods/site/account/nda/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createTextNode("This registration agreement is intended for all research project affiliates accessing Hansen Research Services LLC instruments, data, scores, and reports.");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createTextNode("I agree that any Active Test Links for the Hansen Research Services - Matrix Adaptive Test (HRS-MAT) that are under my control will be used only for the administration of the HRS-MAT to project participants as referenced in Cambridge University Hospitals R&D ref: A093274 and Protocol version 1.0 dated 06.05.2014, and titled: IMAGINE Intellectual Disability and Mental Health: Assessing Genomic Impact on Neurodevelopment (IMAGINE).");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createTextNode("I understand that use of the Active Test Links for the Hansen Research Services - Matrix Adaptive Test (HRS-MAT) for any purpose other than the administration of the HRS-MAT to IMAGINE project participants may result in immediate suspension of all HRS-MAT Active Test Links.");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createTextNode("I understand that Active Test Links for the Hansen Research Services - Matrix Adaptive Test (HRS-MAT) are considered Confidential Information and that I may be a Recipient of this Confidential Information. Recipient shall use the Confidential Information solely to perform their duties in the IMAGINE Research Project and shall not use any Confidential Information to circumvent HRS or for my own benefit.  Recipient shall not reverse engineer, reverse compile or otherwise attempt to derive the composition or underlying information, structure or ideas of any Confidential Information.");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createTextNode("I understand that I will not make or attempt to make any electronic copies, recordings, or screen captures of the HRS-MAT or HRS-MAT materials beyond any that I have been granted explicit written permission by Hansen Research Services LLC.");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createTextNode("By providing your contact information you are agreeing to follow and abide by these Agreement terms. Your email and contact information will be held confidentially and will not be shared outside of Hansen Research Services LLC.");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,13,13,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,15,15,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","paper-button",[],["id","agree","raised",true,"primary",true,"action","agree","label","I agree"],["loc",[null,[21,2],[21,85]]]],
          ["inline","paper-button",[],["raised",true,"action","transitionTo","param","site.signout","label","Disagree"],["loc",[null,[22,2],[22,90]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 26,
            "column": 0
          }
        },
        "moduleName": "test-client/pods/site/account/nda/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","modal-overlay");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
        morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
        return morphs;
      },
      statements: [
        ["block","paper-toolbar",[],["class","modal"],0,null,["loc",[null,[2,0],[6,18]]]],
        ["block","paper-content",[],["class","md-padding modal"],1,null,["loc",[null,[8,0],[23,18]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});