define('test-client/pods/components/size-overlay/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    resize: Ember['default'].inject.service(),
    classNameBindings: ['isSmall:expandedOverlay'],
    isSmall: (function () {
      return this.get('resize.width') < 855 || this.get('resize.height') < 625;
    }).property('resize.width', 'resize.height')
  });

});