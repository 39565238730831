define('test-client/pods/application/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model() {
      // temporary
      window.$('#loading').remove();
    },
    actions: {
      error: function error(_error, transition) {
        transition.router.transitionTo('testing.complete');
        // let Raven report it
        throw _error;
      }
    }
  });

});