define('test-client/pods/testing/complete/route', ['exports', 'ember', 'test-client/utils/fullscreen', 'test-client/config/environment', 'ic-ajax'], function (exports, Ember, fullscreen, config, ajax) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    beforeModel: function beforeModel() {
      var _this = this;

      var id = this.session.get('hash');
      // if the participant is loaded then the project will be too
      if (id && !this.store.peekRecord('participant', id)) {
        return ajax['default'](config['default'].host + '/' + config['default'].namespace + '/participants/' + id + '/project', {
          headers: {
            'X-Participant': id
          }
        }).then(function (data) {
          _this.store.push(data);
        })['catch'](function () {
          Raven.captureMessage('Access to project was denied. Is project active?', {
            level: 'info'
          });
        });
      }
    },
    model: function model() {
      return this.store.peekAll('project').get('firstObject');
    },
    afterModel: function afterModel(model) {
      fullscreen.leaveFullscreen();

      var id = this.session.get('hash'),
          participant = this.modelFor('testing');
      var status;

      if (participant) {
        status = participant.get('status');
      }

      ajax['default'](config['default'].host + '/' + config['default'].namespace + '/participants/' + id + '/finish', {
        type: 'PUT',
        headers: {
          'X-Participant': id
        },
        data: {
          status: status
        }
      })['catch'](function () {
        Raven.captureMessage('PUT /finish failed. Participant has likely refreshed after UUID has expired', {
          level: 'info'
        });
      });

      if (model) {
        var exitUrl = model.get('exitUrl'),
            _participant = this.modelFor('testing');

        if (exitUrl) {
          var _id = '';
          if (_participant) {
            _id = _participant.getWithDefault('subjectIdentifier', '');
          }

          model.set('exitUrl', exitUrl.replace('{0}', _id));

          Ember['default'].run.later(function () {
            window.location.href = model.get('exitUrl');
          }, 10000);
        }
      }
    }
  });

});