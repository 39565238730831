define('test-client/mixins/logic', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var now = function now() {
    return Math.round(new Date().getTime() / 1000);
  };

  var nowMs = function nowMs() {
    return new Date().getTime();
  };

  var pointerCache = 0;

  exports['default'] = Ember['default'].Mixin.create({

    isOverallTimeLimitExceeded: function isOverallTimeLimitExceeded() {
      var startTime = this.get('startTime'),
          testTimeMax = this.get('participant.project.testTimeMax');

      // check overall time limit after each set
      if (now() > startTime + testTimeMax) {
        // terminate the test by sending them to the Completed view
        return true;
      }
    },

    abortForFastClickThrough: function abortForFastClickThrough() {
      if (now() <= Math.round(this.get('puzzleStartTime') / 1000) + 1) {
        this.incrementProperty('fastClickThroughStrikes');
      }

      // check to see if they've just been clicking through rapidly
      if (this.get('fastClickThroughStrikes') >= this.get('participant.project.fastFlagMax')) {
        return true;
      }
    },

    collectPointerEvents: function collectPointerEvents() {
      var _this = this;

      window.$('body').on('mousemove', function (e) {
        var time = nowMs();
        // this is so that we only log an event every 100ms to cut down on storage
        if (time > pointerCache + 100) {
          _this.get('pointerEvents').push({
            t: time,
            c: _this.get('hoveringOver'),
            x: e.screenX,
            y: e.screenY,
            w: window.$(window).width(),
            h: window.$(window).height()
          });
          pointerCache = time;
        }
      });
    },

    preventKeyEvents: function preventKeyEvents() {
      var _this2 = this;

      window.$('body').on('keydown', function (e) {
        _this2.get('keyEvents').push({ t: now(), key: e.which });
        if (e.which !== 27) {
          e.preventDefault();
          /**
           * 16 - Shift
           * 17 - Ctrl
           * 18 - Alt
           * 51 - 3
           * 52 - 4
           * 123 - F12, Developer Tools
           */
          if (e.which === 123) {
            _this2.sendAction('complete');
            Raven.captureMessage('Alert: developer Tools were opened', {
              level: 'info'
            });
          }

          if ([16, 17, 18, 51, 52].indexOf(e.which) > -1) {
            _this2.incrementProperty('specialKeys');
            if (_this2.get('specialKeys') > 9) {
              _this2.sendAction('complete');
              Raven.captureMessage('Alert: shift was pressed 10 or more times', {
                level: 'info'
              });
            }
          }
        }
      });
    },

    preventContextClick: function preventContextClick() {
      window.$('body').on('contextmenu', function (e) {
        e.preventDefault();
      });
    }

  });

});