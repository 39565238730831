define('test-client/pods/components/feedback-form/component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var messages = ['Better luck next time. You scored 0 out of {0} items.', 'Ok, that set was hard. You scored 1 out of {0} items.', 'Not too bad. You scored 2 out of {0} items.', 'Way to go. You scored 3 out of {0} items.', 'Good work. You scored 4 out of {0} items.', 'You are on a roll. You scored 5 out of {0} items.', 'Nice work! You scored 6 out of {0} items.', 'Great job! You scored 7 out of {0} items.', 'Superb work! You scored 8 out of {0} items.', 'Excellent! You scored 9 out of {0} items.', 'Amazing! You scored 10 out of {0} items.', 'Amazing! You scored 11 out of {0} items.', 'Amazing! You scored 12 out of {0} items.', 'Amazing! You scored 13 out of {0} items.', 'Amazing! You scored 14 out of {0} items.', 'Amazing! You scored 15 out of {0} items.'];

  exports['default'] = Ember['default'].Component.extend({
    computeGameScores: (function () {
      var responses = this.get('participant.responses'),
          itemsInSet = this.get('participant.project.itemsInSet'),
          length = responses.get('length'),
          correct = 0,
          set = 1,
          sets = [];

      for (var i = 0; i < length; i++) {
        if (responses.objectAt(i) && responses.objectAt(i).get('correct')) {
          correct++;
        }

        if ((i + 1) % itemsInSet === 0) {
          sets.push({ set: set, correct: correct });
          set++;
          correct = 0;
        }
      }

      this.set('gameScores', sets);
    }).observes('participant.responses.length').on('init'),
    numberCorrect: (function () {
      var responses = this.get('participant.responses'),
          itemsInSet = this.get('participant.project.itemsInSet'),
          length = responses.get('length'),
          correct = 0;
      for (var i = length; i > length - itemsInSet; i--) {
        if (responses.objectAt(i - 1) && responses.objectAt(i - 1).get('correct')) {
          correct++;
        }
      }
      return correct;
    }).property('participant.responses.length'),
    numberCorrectMessage: (function () {
      var correct = this.get('numberCorrect'),
          length = this.get('participant.project.itemsInSet'),
          message = messages[correct];
      return message && message.replace('{0}', length) || 'Good work!';
    }).property('numberCorrect'),
    valueSet: (function () {
      if (typeof this.get('effort') !== 'undefined') {
        this.set('effortRequired');
      }
    }).observes('effort'),
    actions: {
      submitFeedback: function submitFeedback(participant, effort, setStartTime, setEndTime) {
        var _this = this;

        // prevent double click from sending off two requests
        if (this.inFlight) {
          return;
        }
        this.set('effortRequired');
        this.set('isError');
        var duration = Math.round(setEndTime - setStartTime);
        if (isNaN(parseInt(effort))) {
          return this.set('effortRequired', true);
        }

        this.inFlight = true;
        this.store.createRecord('set', { participant: participant, effort: effort, duration: duration }).save().then(function () {
          _this.inFlight = false;
          _this.sendAction();
        })['catch'](function (error) {
          _this.inFlight = false;
          _this.set('isError', true);
          Raven.captureMessage('The set feedback form could not be persisted to the server. The participant was asked to try again', {
            level: 'info'
          });

          throw error;
        });
      }
    }
  });

});