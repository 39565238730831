define('test-client/models/response', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var attr = DS['default'].attr;
  exports['default'] = DS['default'].Model.extend({
    pointerEvents: attr(),
    keyEvents: attr(),
    correct: attr(),
    stop: attr(),
    // the ID of the first pilot item; used to defeat caching
    pilot: attr(),
    theta: attr(),
    psd: attr(),
    // time-to-answer in milliseconds
    time: attr(),
    sequence: attr(),
    item: DS['default'].belongsTo('item'),
    answer: DS['default'].belongsTo('choice'),
    participant: DS['default'].belongsTo('participant', { async: false })
  });

});