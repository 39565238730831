define('test-client/locales/en/translations', ['exports'], function (exports) {

  'use strict';


  exports['default'] = {
    'locale.de': 'Deutsche',
    'locale.en': 'English',
    'locale.fr': 'Français',
    'locale.fr-ca': 'Français',
    'locale.fr-ch': 'Français',
    'locale.nl': 'Nederlands',
    'locale.sv': 'Svenska',
    'locale.it': 'Italiano',
    'locale.es': 'Español',
    'locale.ko': '한국어',
    'locale.da': 'Dansk',
    'locale.ru': 'русский',

    'button.start': 'I Am Ready To Start',
    'button.startTest': 'Start The Puzzles',
    'button.instructions': 'Start Instructions',
    'button.understand': 'Got It',
    'button.continue': 'Continue',
    'button.finished': 'Finished',

    'alert.fullscreen': 'Your browser will switch to fullscreen mode to minimize distractions.',
    'alert.requiredFields': 'Both Gender and Age are required fields.',
    'alert.invalidAge': 'Age (in years) must be a number 3 or greater.',
    'alert.selectEffort': 'Select a number to indicate how hard you tried.',
    'alert.feedbackFormError': 'Sorry, there was an error. Make sure your internet is connected and then try again.',

    'title.welcome': 'Welcome',
    'title.demographics': 'First a little bit about you.',
    'title.instructions': 'Example {{index}}',
    'title.reminders': 'Let\'s get started!',
    'title.feedback': 'How hard did you try?',
    'title.lifestyle': 'Nice work on the puzzles!',
    'title.complete': 'Thank you for your participation!',

    'form.field.required': 'required',

    'form.label.gender': 'Gender',
    'form.label.age': 'Age',
    'form.label.race': 'Race',
    'form.label.education': 'Education',
    'form.label.didNotTry': 'I did not try',
    'form.label.didMyBest': 'I did my best',
    'form.label.set': 'Set',
    'form.label.score': 'Score',
    'form.label.lifestyle.motivation': 'In life I consider myself:',
    'form.label.lifestyle.school': 'In general I like school:',
    'form.label.lifestyle.rest': 'I feel rested from sleep:',
    'form.label.lifestyle.restMinor': 'During the day I feel tired:',
    'form.label.lifestyle.fitness': 'I am physically:',
    'form.label.lifestyle.handedness': 'I use mostly my:',
    'form.label.lifestyle.games': 'I play computer games:',
    'form.label.lifestyle.smoking': 'I am a:',
    'form.label.lifestyle.alcohol': 'I drink alcohol:',
    'form.label.lifestyle.caffeine': 'I drink coffee / tea:',
    'form.label.lifestyle.control': 'In life I feel that I have:',

    'form.choice.info.genderA': 'Male',
    'form.choice.info.genderB': 'Female',
    'form.choice.info.race0': 'American Indian',
    'form.choice.info.race1': 'Asian',
    'form.choice.info.race2': 'Biracial',
    'form.choice.info.race3': 'Black',
    'form.choice.info.race4': 'White',
    'form.choice.info.race5': 'Other',
    'form.choice.info.education0': 'Preschool',
    'form.choice.info.education1': 'Kindergarten',
    'form.choice.info.education2': '1st grade',
    'form.choice.info.education3': '2nd grade',
    'form.choice.info.education4': '3rd grade',
    'form.choice.info.education5': '4th grade',
    'form.choice.info.education6': '5th grade',
    'form.choice.info.education7': '6th grade',
    'form.choice.info.education8': '7th grade',
    'form.choice.info.education9': '8th grade',
    'form.choice.info.education10': '9th grade',
    'form.choice.info.education11': '10th grade',
    'form.choice.info.education12': '11th grade',
    'form.choice.info.education13': '12th grade',
    'form.choice.info.education14': 'Trade school',
    'form.choice.info.education15': 'Some college',
    'form.choice.info.education16': 'Associates degree (example: medical tech, HVAC)',
    'form.choice.info.education17': 'Bachelors degree',
    'form.choice.info.education18': 'Masters degress',
    'form.choice.info.education19': 'Professional degree (example: MD, JD, DDS)',
    'form.choice.info.education20': 'Doctoral degree',
    'form.choice.lifestyle.motivationA': 'Not very motivated',
    'form.choice.lifestyle.motivationB': 'Somewhat motivated',
    'form.choice.lifestyle.motivationC': 'Highly motivated',
    'form.choice.lifestyle.schoolA': 'Not at all',
    'form.choice.lifestyle.schoolB': 'A little',
    'form.choice.lifestyle.schoolC': 'Very much',
    'form.choice.lifestyle.restA': 'Never',
    'form.choice.lifestyle.restB': 'Sometimes',
    'form.choice.lifestyle.restC': 'Always',
    'form.choice.lifestyle.smokingA': 'Non-smoker',
    'form.choice.lifestyle.smokingB': 'Former smoker',
    'form.choice.lifestyle.smokingC': 'Smoker',
    'form.choice.lifestyle.alcoholA': 'Never',
    'form.choice.lifestyle.alcoholB': '1-4 drinks per week',
    'form.choice.lifestyle.alcoholC': '5 or more drinks per week',
    'form.choice.lifestyle.caffeineA': 'Never',
    'form.choice.lifestyle.caffeineB': 'Once a day',
    'form.choice.lifestyle.caffeineC': 'More than once a day',
    'form.choice.lifestyle.controlA': 'Very little control',
    'form.choice.lifestyle.controlB': 'Some control',
    'form.choice.lifestyle.controlC': 'Very good control',
    'form.choice.lifestyle.fitnessA': 'Sedentary (little daily activity)',
    'form.choice.lifestyle.fitnessB': 'Active (moderate daily walking)',
    'form.choice.lifestyle.fitnessC': 'Athletic (work out regularly)',
    'form.choice.lifestyle.handednessA': 'Right hand',
    'form.choice.lifestyle.handednessB': 'Left hand',
    'form.choice.lifestyle.handednessC': 'Both hands',
    'form.choice.lifestyle.gamesA': 'Never',
    'form.choice.lifestyle.gamesB': 'Sometimes',
    'form.choice.lifestyle.gamesC': 'Most days',

    'copy.reminders.nextPage': 'On the next page you will start the puzzles.',
    'copy.reminders.doYourBest': 'Try to do your very best.',
    'copy.reminders.refresh': 'Do not refresh the page.',
    'copy.reminders.distractions': 'Please turn off your cell phone or any other distractions before starting.',
    'copy.lifestyle.instructions': 'Please take a moment to finish up.',
    'copy.complete.noURL': 'We\'re all done here. Thanks, and have a good day!',
    'copy.complete.URL': 'You will be redirected back to',
    'copy.complete.URLTime': 'in 10 seconds.'
  };

});