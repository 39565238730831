define('test-client/models/item', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var attr = DS['default'].attr;
  exports['default'] = DS['default'].Model.extend({
    file: attr(),
    choiceFiles: attr(),
    choices: DS['default'].hasMany('choice', { async: true, inverse: 'item' })
  });

});