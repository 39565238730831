define('test-client/pods/site/account/project/participants/index/controller', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    queryParams: ['q', 'state'],
    q: null,
    state: 'all',
    readQuery: Ember['default'].computed.reads('q')
  });

});