define('test-client/locales/es/translations', ['exports', 'ember', 'test-client/locales/en/translations'], function (exports, Ember, en) {

  'use strict';

  exports['default'] = Ember['default'].merge(Ember['default'].copy(en['default']), {
    'locale.en': 'English',
    'locale.fr': 'Français',

    'button.start': 'Estoy Listo Para Comenzar',
    'button.startTest': 'Comenzar El Ejercicio',
    'button.instructions': 'Comenzar La Instrucción',
    'button.understand': 'Lo Tengo',
    'button.continue': 'Continuar',
    'button.finished': 'Terminar',

    'alert.fullscreen': 'Su navegador pasará al modo de pantalla completa para minimizar las distracciones.',
    'alert.requiredFields': 'Tanto el Género y la Edad son campos requeridos.',
    'alert.invalidAge': 'Edad (en años) debe ser un número 3 or mayor.',
    'alert.selectEffort': 'Arrastre el control deslizante para indicar lo difícil que ha intentado.',

    'title.demographics': 'Primero, un poco sobre usted.',
    'title.instructions': 'Ejemplo {{index}}',
    'title.reminders': 'Empecemos!',
    'title.feedback': 'La fuerza con que lo intentó?',
    'title.lifestyle': 'Buen trabajo en los ejercicios!',
    'title.complete': 'Gracias por su participación!',

    'form.field.required': 'Necesario',

    'form.label.gender': 'Género',
    'form.label.age': 'Edad',
    'form.label.race': 'Raza',
    'form.label.education': 'Educación',
    'form.label.didNotTry': 'No traté',
    'form.label.didMyBest': 'Hice lo mejor que pude',
    'form.label.set': 'Serie',
    'form.label.score': 'Puntuación',
    'form.label.lifestyle.motivation': 'En la vida me considero:',
    'form.label.lifestyle.school': 'En general me gusta la escuela:',
    'form.label.lifestyle.rest': 'Me siento descansado después de dormir:',
    'form.label.lifestyle.restMinor': 'Me siento cansado durante el dia:',
    'form.label.lifestyle.fitness': 'Estoy físicamente:',
    'form.label.lifestyle.handedness': 'Sobre todo yo utilizo:',
    'form.label.lifestyle.games': 'Yo juego juegos de computadora:',
    'form.label.lifestyle.smoking': 'Yo soy un:',
    'form.label.lifestyle.alcohol': 'Yo bebo alcohol:',
    'form.label.lifestyle.caffeine': 'Yo bebo café / té:',
    'form.label.lifestyle.control': 'En la vida siento que tengo:',

    'form.choice.info.genderA': 'Masculino',
    'form.choice.info.genderB': 'Femenino',
    'form.choice.info.race0': 'Indio Americano',
    'form.choice.info.race1': 'Asiático',
    'form.choice.info.race2': 'Birracial',
    'form.choice.info.race3': 'Moreno',
    'form.choice.info.race4': 'Caucásico',
    'form.choice.info.race5': 'Otro',
    'form.choice.info.education0': 'Preescolar',
    'form.choice.info.education1': 'Kinder',
    'form.choice.info.education2': 'Primero de Primaria',
    'form.choice.info.education3': 'Segundo de Primaria',
    'form.choice.info.education4': 'Tercero de Primaria',
    'form.choice.info.education5': 'Quarto de Primaria',
    'form.choice.info.education6': 'Quinto de Primaria',
    'form.choice.info.education7': 'Sexto de Primaria',
    'form.choice.info.education8': 'Primero de Secundaria',
    'form.choice.info.education9': 'Segundo de Secundaria',
    'form.choice.info.education10': 'Tercero de Secundaria',
    'form.choice.info.education11': 'Primero de Preparatoria',
    'form.choice.info.education12': 'Segundo de Preparatoria',
    'form.choice.info.education13': 'Tercero de Preparatoria',
    'form.choice.info.education14': 'Escuela de Comercio',
    'form.choice.info.education15': 'Alguna educación superior',
    'form.choice.info.education16': 'Grado de Asociados (ejemplo: Technología Médica, Climatización)',
    'form.choice.info.education17': 'Licenciatura',
    'form.choice.info.education18': 'Maestría',
    'form.choice.info.education19': 'Titulo Profesional (ejemplo: Doctor, Abogado, Dentista)',
    'form.choice.info.education20': 'Doctorado',
    'form.choice.lifestyle.motivationA': 'No muy motivado',
    'form.choice.lifestyle.motivationB': 'Algo motivado',
    'form.choice.lifestyle.motivationC': 'Altamente motivado',
    'form.choice.lifestyle.schoolA': 'Para nada',
    'form.choice.lifestyle.schoolB': 'Un Poco',
    'form.choice.lifestyle.schoolC': 'Mucho',
    'form.choice.lifestyle.restA': 'Nunca',
    'form.choice.lifestyle.restB': 'A veces',
    'form.choice.lifestyle.restC': 'Siempre',
    'form.choice.lifestyle.smokingA': 'No fumador',
    'form.choice.lifestyle.smokingB': 'Ex fumador',
    'form.choice.lifestyle.smokingC': 'Fumador',
    'form.choice.lifestyle.alcoholA': 'nunca',
    'form.choice.lifestyle.alcoholB': '1-4 bebidas por semana',
    'form.choice.lifestyle.alcoholC': '5 o mas bebidas por semana',
    'form.choice.lifestyle.caffeineA': 'nunca',
    'form.choice.lifestyle.caffeineB': 'Una vez al dia',
    'form.choice.lifestyle.caffeineC': 'Mas de una vez al dia',
    'form.choice.lifestyle.controlA': 'Muy poco control',
    'form.choice.lifestyle.controlB': 'Algo de control',
    'form.choice.lifestyle.controlC': 'Muy buen control',
    'form.choice.lifestyle.fitnessA': 'Sedentario (poca actividad diaria)',
    'form.choice.lifestyle.fitnessB': 'Activo (caminata diaria moderada)',
    'form.choice.lifestyle.fitnessC': 'Atlético (hace ejercicio con regularidad)',
    'form.choice.lifestyle.handednessA': 'mano derecha',
    'form.choice.lifestyle.handednessB': 'mano izquierda',
    'form.choice.lifestyle.handednessC': 'Ambas manos',
    'form.choice.lifestyle.gamesA': 'Nunca',
    'form.choice.lifestyle.gamesB': 'a veces',
    'form.choice.lifestyle.gamesC': 'La mayoría de los días',

    'copy.reminders.nextPage': 'En la siguiente pagina usted comenzara los ejercicios.',
    'copy.reminders.doYourBest': 'Trate de hacer su mejor esfuerzo.',
    'copy.reminders.refresh': 'No actualice la página.',
    'copy.reminders.distractions': 'Por favor apague su teléfono celular u otras distracciones antes de comenzar.',
    'copy.lifestyle.instructions': 'Por favor tome un momento para terminar.',
    'copy.complete.noURL': 'Hemos terminado aqui. Gracias y que tenga un buen día!',
    'copy.complete.URL': 'Usted será redirigido de regreso a',
    'copy.complete.URLTime': 'en diez segundos.'
  });

});