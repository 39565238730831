define('test-client/models/project', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var attr = DS['default'].attr;
  exports['default'] = DS['default'].Model.extend({
    // A human readable name for this project
    name: attr(),
    // An HTML payload to display on the first page of the project
    introduction: attr(),
    // whether we show them the Race and Education form or not
    collectDemographics: attr(),
    // whether we show the language switcher or not
    enableLocalization: attr(),
    // whether the researcher may reset a participant or not
    enableParticipantReset: attr(),
    // test items per set
    itemsInSet: attr(),
    // the minimum number or participant must take
    numberOfItemsMin: attr(),
    numberOfItemsMinMinor: attr(),
    // after completing this many participant is eligible for pilot items
    numberOfItemsMax: attr(),
    numberOfItemsMaxMinor: attr(),
    // max amount of time participant may spend taking tests
    testTimeMax: attr(),
    testTimeMaxMinor: attr(),
    // number of strikes allowable due to rapidly selecting wrong answers
    fastFlagMax: attr(),
    // if theta falls below this before 10 items then we abort
    testTooHardThreshold: attr(),
    // when psd reaches this we move to pilot items
    psdStop: attr(),
    errorStop: attr(),
    // the url to redirect too after tests are finished
    exitUrl: attr(),
    participantCount: attr(),
    startedParticipantCount: attr(),
    completedParticipantCount: attr(),
    // The puzzle record that we start the adaptive test with
    entryItem: DS['default'].belongsTo('item', { async: false }),
    instructions: DS['default'].hasMany('instruction', { async: false })
  });

});