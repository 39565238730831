define('test-client/pods/site/signin/route', ['exports', 'ember', 'ic-ajax', 'test-client/config/environment'], function (exports, Ember, ajax, config) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    actions: {
      submit: function submit(email, password, resetPassword) {
        var _this = this;

        if (!email || !password && !resetPassword) {
          return this.controller.set('error', true);
        }

        var parts = [config['default'].host, config['default'].namespace];
        var action = resetPassword ? 'reset-password-link' : 'token';
        ajax['default'](parts.reduce(function (a, b) {
          return a + (b ? b + '/' : '');
        }, '') + action, {
          type: 'POST',
          data: JSON.stringify({
            data: {
              type: 'auth',
              attributes: {
                username: email,
                password: password
              }
            }
          }),
          contentType: 'application/vnd.api+json',
          dataType: 'json'
        }).then(function (result) {
          if (resetPassword) {
            _this.controller.set('email');
            _this.controller.set('error');
            return _this.controller.set('resetMessage', true);
          }
          _this.controller.set('error');
          _this.controller.set('resetMessage');
          _this.storage.set('token', result.data.token);
          _this.storage.set('userID', result.data.user);
          _this.transitionTo('site.account.legal');
        })['catch'](function () {
          _this.controller.set('error', true);
        });
      }
    }
  });

});