define('test-client/templates/components/paper-autocomplete', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 13,
              "column": 2
            }
          },
          "moduleName": "test-client/templates/components/paper-autocomplete.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","paper-input",[],["type","search","label",["subexpr","@mut",[["get","placeholder",["loc",[null,[5,12],[5,23]]]]],[],[]],"focus-in","inputFocusIn","focus-out","inputFocusOut","key-down","inputKeyDown","value",["subexpr","@mut",[["get","searchText",["loc",[null,[9,12],[9,22]]]]],[],[]],"disabled",["subexpr","@mut",[["get","disabled",["loc",[null,[10,15],[10,23]]]]],[],[]],"required",["subexpr","@mut",[["get","required",["loc",[null,[11,15],[11,23]]]]],[],[]],"flex",true],["loc",[null,[3,4],[12,17]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 30,
                  "column": 6
                },
                "end": {
                  "line": 32,
                  "column": 6
                }
              },
              "moduleName": "test-client/templates/components/paper-autocomplete.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","paper-icon",[],["icon","close"],["loc",[null,[31,8],[31,35]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 29,
                "column": 4
              },
              "end": {
                "line": 33,
                "column": 4
              }
            },
            "moduleName": "test-client/templates/components/paper-autocomplete.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","paper-button",[],["icon-button",true,"themed",false,"action","clear"],0,null,["loc",[null,[30,6],[32,23]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 13,
              "column": 2
            },
            "end": {
              "line": 35,
              "column": 2
            }
          },
          "moduleName": "test-client/templates/components/paper-autocomplete.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","input",[],["type","search","flex",true,"placeholder",["subexpr","@mut",[["get","placeholder",["loc",[null,[16,18],[16,29]]]]],[],[]],"value",["subexpr","@mut",[["get","searchText",["loc",[null,[17,12],[17,22]]]]],[],[]],"focus-in","inputFocusIn","focus-out","inputFocusOut","key-down","inputKeyDown","autocomplete","off","disabled",["subexpr","@mut",[["get","disabled",["loc",[null,[22,15],[22,23]]]]],[],[]],"required",["subexpr","@mut",[["get","required",["loc",[null,[23,15],[23,23]]]]],[],[]],"aria-haspopup",true,"aria-autocomplete","list","aria-activedescendant","","aria-expanded",["subexpr","@mut",[["get","notHidden",["loc",[null,[27,20],[27,29]]]]],[],[]]],["loc",[null,[14,4],[27,31]]]],
          ["block","if",[["get","enableClearButton",["loc",[null,[29,10],[29,27]]]]],[],0,null,["loc",[null,[29,4],[33,11]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 37,
              "column": 2
            },
            "end": {
              "line": 39,
              "column": 2
            }
          },
          "moduleName": "test-client/templates/components/paper-autocomplete.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["content","paper-progress-linear",["loc",[null,[38,4],[38,29]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 48,
                      "column": 12
                    },
                    "end": {
                      "line": 50,
                      "column": 12
                    }
                  },
                  "moduleName": "test-client/templates/components/paper-autocomplete.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","yield",[["get","searchText",["loc",[null,[49,22],[49,32]]]],["get","item",["loc",[null,[49,33],[49,37]]]],["get","index",["loc",[null,[49,38],[49,43]]]]],[],["loc",[null,[49,14],[49,45]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.13",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 51,
                        "column": 14
                      },
                      "end": {
                        "line": 53,
                        "column": 14
                      }
                    },
                    "moduleName": "test-client/templates/components/paper-autocomplete.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    return morphs;
                  },
                  statements: [
                    ["inline","component",[["get","itemComponent",["loc",[null,[52,28],[52,41]]]]],["searchText",["subexpr","@mut",[["get","searchText",["loc",[null,[52,53],[52,63]]]]],[],[]],"label",["subexpr","@mut",[["get","label",["loc",[null,[52,70],[52,75]]]]],[],[]],"index",["subexpr","@mut",[["get","index",["loc",[null,[52,82],[52,87]]]]],[],[]]],["loc",[null,[52,16],[52,89]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.13",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 53,
                        "column": 14
                      },
                      "end": {
                        "line": 55,
                        "column": 14
                      }
                    },
                    "moduleName": "test-client/templates/components/paper-autocomplete.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    return morphs;
                  },
                  statements: [
                    ["inline","paper-autocomplete-highlight",[],["searchText",["subexpr","@mut",[["get","searchText",["loc",[null,[54,58],[54,68]]]]],[],[]],"label",["subexpr","@mut",[["get","label",["loc",[null,[54,75],[54,80]]]]],[],[]]],["loc",[null,[54,16],[54,82]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 50,
                      "column": 12
                    },
                    "end": {
                      "line": 56,
                      "column": 12
                    }
                  },
                  "moduleName": "test-client/templates/components/paper-autocomplete.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","itemComponent",["loc",[null,[51,20],[51,33]]]]],[],0,1,["loc",[null,[51,14],[55,21]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 46,
                    "column": 10
                  },
                  "end": {
                    "line": 57,
                    "column": 10
                  }
                },
                "moduleName": "test-client/templates/components/paper-autocomplete.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","hasBlock",["loc",[null,[48,18],[48,26]]]]],[],0,1,["loc",[null,[48,12],[56,19]]]]
              ],
              locals: ["label"],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 44,
                  "column": 8
                },
                "end": {
                  "line": 59,
                  "column": 8
                }
              },
              "moduleName": "test-client/templates/components/paper-autocomplete.hbs"
            },
            arity: 2,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["block","paper-autocomplete-item",[],["lookupKey",["subexpr","@mut",[["get","lookupKey",["loc",[null,[46,47],[46,56]]]]],[],[]],"item",["subexpr","@mut",[["get","item",["loc",[null,[46,62],[46,66]]]]],[],[]],"selectedIndex",["subexpr","@mut",[["get","selectedIndex",["loc",[null,[46,81],[46,94]]]]],[],[]],"index",["subexpr","@mut",[["get","index",["loc",[null,[46,101],[46,106]]]]],[],[]],"pick","pickModel"],0,null,["loc",[null,[46,10],[57,38]]]]
            ],
            locals: ["item","index"],
            templates: [child0]
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 62,
                      "column": 12
                    },
                    "end": {
                      "line": 64,
                      "column": 12
                    }
                  },
                  "moduleName": "test-client/templates/components/paper-autocomplete.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("li");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                  return morphs;
                },
                statements: [
                  ["inline","yield",[["get","searchText",["loc",[null,[63,28],[63,38]]]]],["to","inverse"],["loc",[null,[63,20],[63,53]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.13",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 65,
                        "column": 14
                      },
                      "end": {
                        "line": 67,
                        "column": 14
                      }
                    },
                    "moduleName": "test-client/templates/components/paper-autocomplete.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("li");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["inline","component",[["get","notFoundComponent",["loc",[null,[66,34],[66,51]]]]],["searchText",["subexpr","@mut",[["get","searchText",["loc",[null,[66,63],[66,73]]]]],[],[]]],["loc",[null,[66,22],[66,75]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.13",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 67,
                        "column": 14
                      },
                      "end": {
                        "line": 69,
                        "column": 14
                      }
                    },
                    "moduleName": "test-client/templates/components/paper-autocomplete.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("li");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["content","notFoundMsg",["loc",[null,[68,22],[68,37]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 64,
                      "column": 12
                    },
                    "end": {
                      "line": 70,
                      "column": 12
                    }
                  },
                  "moduleName": "test-client/templates/components/paper-autocomplete.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","notFoundComponent",["loc",[null,[65,20],[65,37]]]]],[],0,1,["loc",[null,[65,14],[69,21]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 60,
                    "column": 10
                  },
                  "end": {
                    "line": 71,
                    "column": 10
                  }
                },
                "moduleName": "test-client/templates/components/paper-autocomplete.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","hasBlock",["loc",[null,[62,18],[62,26]]]]],[],0,1,["loc",[null,[62,12],[70,19]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 59,
                  "column": 8
                },
                "end": {
                  "line": 72,
                  "column": 8
                }
              },
              "moduleName": "test-client/templates/components/paper-autocomplete.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","showLoadingBar",["loc",[null,[60,16],[60,30]]]]],[],0,null,["loc",[null,[60,10],[71,17]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 42,
                "column": 4
              },
              "end": {
                "line": 73,
                "column": 4
              }
            },
            "moduleName": "test-client/templates/components/paper-autocomplete.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","each",[["get","suggestions",["loc",[null,[44,16],[44,27]]]]],[],0,1,["loc",[null,[44,8],[72,17]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 41,
              "column": 2
            },
            "end": {
              "line": 74,
              "column": 2
            }
          },
          "moduleName": "test-client/templates/components/paper-autocomplete.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","paper-autocomplete-list",[],["suggestions",["subexpr","@mut",[["get","suggestions",["loc",[null,[42,43],[42,54]]]]],[],[]],"selectedIndex",["subexpr","@mut",[["get","selectedIndex",["loc",[null,[42,69],[42,82]]]]],[],[]],"wrapToElementId",["subexpr","@mut",[["get","autocompleteWrapperId",["loc",[null,[42,99],[42,120]]]]],[],[]],"mouse-up","listMouseUp","mouse-leave","listMouseLeave","mouse-enter","listMouseEnter"],0,null,["loc",[null,[42,4],[73,32]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child4 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 79,
                "column": 4
              },
              "end": {
                "line": 81,
                "column": 4
              }
            },
            "moduleName": "test-client/templates/components/paper-autocomplete.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            return morphs;
          },
          statements: [
            ["content","message",["loc",[null,[80,11],[80,22]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 78,
              "column": 2
            },
            "end": {
              "line": 82,
              "column": 2
            }
          },
          "moduleName": "test-client/templates/components/paper-autocomplete.hbs"
        },
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","message",["loc",[null,[79,10],[79,17]]]]],[],0,null,["loc",[null,[79,4],[81,11]]]]
        ],
        locals: ["message","index"],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 84,
            "column": 0
          }
        },
        "moduleName": "test-client/templates/components/paper-autocomplete.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("md-autocomplete-wrap");
        dom.setAttribute(el1,"role","listbox");
        dom.setAttribute(el1,"layout","row");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("aria-status");
        dom.setAttribute(el1,"class","md-visually-hidden");
        dom.setAttribute(el1,"role","status");
        dom.setAttribute(el1,"aria-live","assertive");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(6);
        morphs[0] = dom.createAttrMorph(element0, 'id');
        morphs[1] = dom.createAttrMorph(element0, 'class');
        morphs[2] = dom.createMorphAt(element0,1,1);
        morphs[3] = dom.createMorphAt(element0,3,3);
        morphs[4] = dom.createMorphAt(element0,5,5);
        morphs[5] = dom.createMorphAt(dom.childAt(fragment, [2]),1,1);
        return morphs;
      },
      statements: [
        ["attribute","id",["get","autocompleteWrapperId",["loc",[null,[1,27],[1,48]]]]],
        ["attribute","class",["concat",[["subexpr","if",[["get","notFloating",["loc",[null,[1,91],[1,102]]]],"md-whiteframe-z1"],[],["loc",[null,[1,86],[1,123]]]]," ",["subexpr","if",[["get","notHidden",["loc",[null,[1,129],[1,138]]]],"md-menu-showing"],[],["loc",[null,[1,124],[1,158]]]]]]],
        ["block","if",[["get","floating",["loc",[null,[2,8],[2,16]]]]],[],0,1,["loc",[null,[2,2],[35,9]]]],
        ["block","if",[["get","loading",["loc",[null,[37,8],[37,15]]]]],[],2,null,["loc",[null,[37,2],[39,9]]]],
        ["block","if",[["get","notHidden",["loc",[null,[41,8],[41,17]]]]],[],3,null,["loc",[null,[41,2],[74,9]]]],
        ["block","each",[["get","messages",["loc",[null,[78,10],[78,18]]]]],[],4,null,["loc",[null,[78,2],[82,11]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  }()));

});