define('test-client/pods/site/account/project/participants/definitions/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model() {
      var params = this.paramsFor('site.account.project');
      return this.store.peekRecord('project', params.id);
    }
  });

});