define('test-client/pods/site/account/legal/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    storage: Ember['default'].inject.service(),
    model: function model() {
      return this.modelFor('site.account');
    },
    actions: {
      agree: function agree() {
        this.storage.set('legal', true);
        var next = this.controllerFor('site.signin').get('nextTransition');
        if (next) {
          this.controllerFor('site.signin').set('nextTransition');
          return next.retry();
        }
        this.transitionTo('site.account');
      }
    }
  });

});