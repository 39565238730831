define('test-client/models/key', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var attr = DS['default'].attr;
  exports['default'] = DS['default'].Model.extend({
    key: attr(),
    secret: attr(),
    description: attr()
  });

});