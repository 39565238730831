define('test-client/instance-initializers/language', ['exports'], function (exports) {

  'use strict';


  exports['default'] = {
    name: 'i18n',
    initialize: function initialize(_ref) {
      var container = _ref.container;

      var nav = window.navigator;
      var loc = nav.languages && nav.languages[0] || nav.language || nav.userLanguage || 'en';
      var i18n = container.lookup('service:i18n');
      if (i18n.get('locales').indexOf(loc) === -1) {
        loc = 'en';
      }
      i18n.set('locale', loc.replace(/-.*/, ''));
    }
  };

});