define('test-client/pods/testing/lifestyle/template', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 10
              },
              "end": {
                "line": 14,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.motivationA"],[],["loc",[null,[13,12],[13,53]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 10
              },
              "end": {
                "line": 18,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.motivationB"],[],["loc",[null,[17,12],[17,53]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 10
              },
              "end": {
                "line": 22,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.motivationC"],[],["loc",[null,[21,12],[21,53]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 27,
                "column": 10
              },
              "end": {
                "line": 29,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.schoolA"],[],["loc",[null,[28,12],[28,49]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 31,
                "column": 10
              },
              "end": {
                "line": 33,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.schoolB"],[],["loc",[null,[32,12],[32,49]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child5 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 35,
                "column": 10
              },
              "end": {
                "line": 37,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.schoolC"],[],["loc",[null,[36,12],[36,49]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child6 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 42,
                "column": 10
              },
              "end": {
                "line": 44,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.restA"],[],["loc",[null,[43,12],[43,47]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child7 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 46,
                "column": 10
              },
              "end": {
                "line": 48,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.restB"],[],["loc",[null,[47,12],[47,47]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child8 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 50,
                "column": 10
              },
              "end": {
                "line": 52,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.restC"],[],["loc",[null,[51,12],[51,47]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child9 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 57,
                "column": 10
              },
              "end": {
                "line": 59,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.fitnessA"],[],["loc",[null,[58,12],[58,50]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child10 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 61,
                "column": 10
              },
              "end": {
                "line": 63,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.fitnessB"],[],["loc",[null,[62,12],[62,50]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child11 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 65,
                "column": 10
              },
              "end": {
                "line": 67,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.fitnessC"],[],["loc",[null,[66,12],[66,50]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child12 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 72,
                "column": 10
              },
              "end": {
                "line": 74,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.handednessA"],[],["loc",[null,[73,12],[73,53]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child13 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 76,
                "column": 10
              },
              "end": {
                "line": 78,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.handednessB"],[],["loc",[null,[77,12],[77,53]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child14 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 80,
                "column": 10
              },
              "end": {
                "line": 82,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.handednessC"],[],["loc",[null,[81,12],[81,53]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child15 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 87,
                "column": 10
              },
              "end": {
                "line": 89,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.gamesA"],[],["loc",[null,[88,12],[88,48]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child16 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 91,
                "column": 10
              },
              "end": {
                "line": 93,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.gamesB"],[],["loc",[null,[92,12],[92,48]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child17 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 95,
                "column": 10
              },
              "end": {
                "line": 97,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.gamesC"],[],["loc",[null,[96,12],[96,48]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 6
            },
            "end": {
              "line": 100,
              "column": 6
            }
          },
          "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","lifestyle-group");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","lifestyle-group");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","lifestyle-group");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","lifestyle-group");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","lifestyle-group");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","lifestyle-group");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element9 = dom.childAt(fragment, [1]);
          var element10 = dom.childAt(fragment, [3]);
          var element11 = dom.childAt(fragment, [5]);
          var element12 = dom.childAt(fragment, [7]);
          var element13 = dom.childAt(fragment, [9]);
          var element14 = dom.childAt(fragment, [11]);
          var morphs = new Array(24);
          morphs[0] = dom.createMorphAt(dom.childAt(element9, [1]),0,0);
          morphs[1] = dom.createMorphAt(element9,3,3);
          morphs[2] = dom.createMorphAt(element9,5,5);
          morphs[3] = dom.createMorphAt(element9,7,7);
          morphs[4] = dom.createMorphAt(dom.childAt(element10, [1]),0,0);
          morphs[5] = dom.createMorphAt(element10,3,3);
          morphs[6] = dom.createMorphAt(element10,5,5);
          morphs[7] = dom.createMorphAt(element10,7,7);
          morphs[8] = dom.createMorphAt(dom.childAt(element11, [1]),0,0);
          morphs[9] = dom.createMorphAt(element11,3,3);
          morphs[10] = dom.createMorphAt(element11,5,5);
          morphs[11] = dom.createMorphAt(element11,7,7);
          morphs[12] = dom.createMorphAt(dom.childAt(element12, [1]),0,0);
          morphs[13] = dom.createMorphAt(element12,3,3);
          morphs[14] = dom.createMorphAt(element12,5,5);
          morphs[15] = dom.createMorphAt(element12,7,7);
          morphs[16] = dom.createMorphAt(dom.childAt(element13, [1]),0,0);
          morphs[17] = dom.createMorphAt(element13,3,3);
          morphs[18] = dom.createMorphAt(element13,5,5);
          morphs[19] = dom.createMorphAt(element13,7,7);
          morphs[20] = dom.createMorphAt(dom.childAt(element14, [1]),0,0);
          morphs[21] = dom.createMorphAt(element14,3,3);
          morphs[22] = dom.createMorphAt(element14,5,5);
          morphs[23] = dom.createMorphAt(element14,7,7);
          return morphs;
        },
        statements: [
          ["inline","t",["form.label.lifestyle.motivation"],[],["loc",[null,[11,13],[11,52]]]],
          ["block","radio-button",[],["value","0","groupValue",["subexpr","@mut",[["get","model.motivation",["loc",[null,[12,47],[12,63]]]]],[],[]],"class","pure-radio"],0,null,["loc",[null,[12,10],[14,27]]]],
          ["block","radio-button",[],["value","1","groupValue",["subexpr","@mut",[["get","model.motivation",["loc",[null,[16,47],[16,63]]]]],[],[]],"class","pure-radio"],1,null,["loc",[null,[16,10],[18,27]]]],
          ["block","radio-button",[],["value","2","groupValue",["subexpr","@mut",[["get","model.motivation",["loc",[null,[20,47],[20,63]]]]],[],[]],"class","pure-radio"],2,null,["loc",[null,[20,10],[22,27]]]],
          ["inline","t",["form.label.lifestyle.school"],[],["loc",[null,[26,13],[26,48]]]],
          ["block","radio-button",[],["value","0","groupValue",["subexpr","@mut",[["get","model.school",["loc",[null,[27,47],[27,59]]]]],[],[]],"class","pure-radio"],3,null,["loc",[null,[27,10],[29,27]]]],
          ["block","radio-button",[],["value","1","groupValue",["subexpr","@mut",[["get","model.school",["loc",[null,[31,47],[31,59]]]]],[],[]],"class","pure-radio"],4,null,["loc",[null,[31,10],[33,27]]]],
          ["block","radio-button",[],["value","2","groupValue",["subexpr","@mut",[["get","model.school",["loc",[null,[35,47],[35,59]]]]],[],[]],"class","pure-radio"],5,null,["loc",[null,[35,10],[37,27]]]],
          ["inline","t",["form.label.lifestyle.rest"],[],["loc",[null,[41,13],[41,46]]]],
          ["block","radio-button",[],["value","0","groupValue",["subexpr","@mut",[["get","model.rest",["loc",[null,[42,47],[42,57]]]]],[],[]],"class","pure-radio"],6,null,["loc",[null,[42,10],[44,27]]]],
          ["block","radio-button",[],["value","1","groupValue",["subexpr","@mut",[["get","model.rest",["loc",[null,[46,47],[46,57]]]]],[],[]],"class","pure-radio"],7,null,["loc",[null,[46,10],[48,27]]]],
          ["block","radio-button",[],["value","2","groupValue",["subexpr","@mut",[["get","model.rest",["loc",[null,[50,47],[50,57]]]]],[],[]],"class","pure-radio"],8,null,["loc",[null,[50,10],[52,27]]]],
          ["inline","t",["form.label.lifestyle.fitness"],[],["loc",[null,[56,13],[56,49]]]],
          ["block","radio-button",[],["value","0","groupValue",["subexpr","@mut",[["get","model.fitness",["loc",[null,[57,47],[57,60]]]]],[],[]],"class","pure-radio"],9,null,["loc",[null,[57,10],[59,27]]]],
          ["block","radio-button",[],["value","1","groupValue",["subexpr","@mut",[["get","model.fitness",["loc",[null,[61,47],[61,60]]]]],[],[]],"class","pure-radio"],10,null,["loc",[null,[61,10],[63,27]]]],
          ["block","radio-button",[],["value","2","groupValue",["subexpr","@mut",[["get","model.fitness",["loc",[null,[65,47],[65,60]]]]],[],[]],"class","pure-radio"],11,null,["loc",[null,[65,10],[67,27]]]],
          ["inline","t",["form.label.lifestyle.handedness"],[],["loc",[null,[71,13],[71,52]]]],
          ["block","radio-button",[],["value","0","groupValue",["subexpr","@mut",[["get","model.handedness",["loc",[null,[72,47],[72,63]]]]],[],[]],"class","pure-radio"],12,null,["loc",[null,[72,10],[74,27]]]],
          ["block","radio-button",[],["value","1","groupValue",["subexpr","@mut",[["get","model.handedness",["loc",[null,[76,47],[76,63]]]]],[],[]],"class","pure-radio"],13,null,["loc",[null,[76,10],[78,27]]]],
          ["block","radio-button",[],["value","2","groupValue",["subexpr","@mut",[["get","model.handedness",["loc",[null,[80,47],[80,63]]]]],[],[]],"class","pure-radio"],14,null,["loc",[null,[80,10],[82,27]]]],
          ["inline","t",["form.label.lifestyle.games"],[],["loc",[null,[86,13],[86,47]]]],
          ["block","radio-button",[],["value","0","groupValue",["subexpr","@mut",[["get","model.games",["loc",[null,[87,47],[87,58]]]]],[],[]],"class","pure-radio"],15,null,["loc",[null,[87,10],[89,27]]]],
          ["block","radio-button",[],["value","1","groupValue",["subexpr","@mut",[["get","model.games",["loc",[null,[91,47],[91,58]]]]],[],[]],"class","pure-radio"],16,null,["loc",[null,[91,10],[93,27]]]],
          ["block","radio-button",[],["value","2","groupValue",["subexpr","@mut",[["get","model.games",["loc",[null,[95,47],[95,58]]]]],[],[]],"class","pure-radio"],17,null,["loc",[null,[95,10],[97,27]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17]
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 104,
                "column": 10
              },
              "end": {
                "line": 106,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.smokingA"],[],["loc",[null,[105,12],[105,50]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 108,
                "column": 10
              },
              "end": {
                "line": 110,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.smokingB"],[],["loc",[null,[109,12],[109,50]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 112,
                "column": 10
              },
              "end": {
                "line": 114,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.smokingC"],[],["loc",[null,[113,12],[113,50]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 119,
                "column": 10
              },
              "end": {
                "line": 121,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.alcoholA"],[],["loc",[null,[120,12],[120,50]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 123,
                "column": 10
              },
              "end": {
                "line": 125,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.alcoholB"],[],["loc",[null,[124,12],[124,50]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child5 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 127,
                "column": 10
              },
              "end": {
                "line": 129,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.alcoholC"],[],["loc",[null,[128,12],[128,50]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child6 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 134,
                "column": 10
              },
              "end": {
                "line": 136,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.caffeineA"],[],["loc",[null,[135,12],[135,51]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child7 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 138,
                "column": 10
              },
              "end": {
                "line": 140,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.caffeineB"],[],["loc",[null,[139,12],[139,51]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child8 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 142,
                "column": 10
              },
              "end": {
                "line": 144,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.caffeineC"],[],["loc",[null,[143,12],[143,51]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child9 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 149,
                "column": 10
              },
              "end": {
                "line": 151,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.motivationA"],[],["loc",[null,[150,12],[150,53]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child10 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 153,
                "column": 10
              },
              "end": {
                "line": 155,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.motivationB"],[],["loc",[null,[154,12],[154,53]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child11 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 157,
                "column": 10
              },
              "end": {
                "line": 159,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.motivationC"],[],["loc",[null,[158,12],[158,53]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child12 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 164,
                "column": 10
              },
              "end": {
                "line": 166,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.controlA"],[],["loc",[null,[165,12],[165,50]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child13 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 168,
                "column": 10
              },
              "end": {
                "line": 170,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.controlB"],[],["loc",[null,[169,12],[169,50]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child14 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 172,
                "column": 10
              },
              "end": {
                "line": 174,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.controlC"],[],["loc",[null,[173,12],[173,50]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child15 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 179,
                "column": 10
              },
              "end": {
                "line": 181,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.restA"],[],["loc",[null,[180,12],[180,47]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child16 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 183,
                "column": 10
              },
              "end": {
                "line": 185,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.restB"],[],["loc",[null,[184,12],[184,47]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child17 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 187,
                "column": 10
              },
              "end": {
                "line": 189,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.restC"],[],["loc",[null,[188,12],[188,47]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child18 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 194,
                "column": 10
              },
              "end": {
                "line": 196,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.fitnessA"],[],["loc",[null,[195,12],[195,50]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child19 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 198,
                "column": 10
              },
              "end": {
                "line": 200,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.fitnessB"],[],["loc",[null,[199,12],[199,50]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child20 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 202,
                "column": 10
              },
              "end": {
                "line": 204,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.fitnessC"],[],["loc",[null,[203,12],[203,50]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child21 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 209,
                "column": 10
              },
              "end": {
                "line": 211,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.handednessA"],[],["loc",[null,[210,12],[210,53]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child22 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 213,
                "column": 10
              },
              "end": {
                "line": 215,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.handednessB"],[],["loc",[null,[214,12],[214,53]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child23 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 217,
                "column": 10
              },
              "end": {
                "line": 219,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.handednessC"],[],["loc",[null,[218,12],[218,53]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child24 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 224,
                "column": 10
              },
              "end": {
                "line": 226,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.gamesA"],[],["loc",[null,[225,12],[225,48]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child25 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 228,
                "column": 10
              },
              "end": {
                "line": 230,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.gamesB"],[],["loc",[null,[229,12],[229,48]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child26 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 232,
                "column": 10
              },
              "end": {
                "line": 234,
                "column": 10
              }
            },
            "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","t",["form.choice.lifestyle.gamesC"],[],["loc",[null,[233,12],[233,48]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 100,
              "column": 6
            },
            "end": {
              "line": 237,
              "column": 6
            }
          },
          "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","lifestyle-group");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","lifestyle-group");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","lifestyle-group");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","lifestyle-group");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","lifestyle-group");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","lifestyle-group");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","lifestyle-group");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","lifestyle-group");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","lifestyle-group");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(fragment, [3]);
          var element2 = dom.childAt(fragment, [5]);
          var element3 = dom.childAt(fragment, [7]);
          var element4 = dom.childAt(fragment, [9]);
          var element5 = dom.childAt(fragment, [11]);
          var element6 = dom.childAt(fragment, [13]);
          var element7 = dom.childAt(fragment, [15]);
          var element8 = dom.childAt(fragment, [17]);
          var morphs = new Array(36);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]),0,0);
          morphs[1] = dom.createMorphAt(element0,3,3);
          morphs[2] = dom.createMorphAt(element0,5,5);
          morphs[3] = dom.createMorphAt(element0,7,7);
          morphs[4] = dom.createMorphAt(dom.childAt(element1, [1]),0,0);
          morphs[5] = dom.createMorphAt(element1,3,3);
          morphs[6] = dom.createMorphAt(element1,5,5);
          morphs[7] = dom.createMorphAt(element1,7,7);
          morphs[8] = dom.createMorphAt(dom.childAt(element2, [1]),0,0);
          morphs[9] = dom.createMorphAt(element2,3,3);
          morphs[10] = dom.createMorphAt(element2,5,5);
          morphs[11] = dom.createMorphAt(element2,7,7);
          morphs[12] = dom.createMorphAt(dom.childAt(element3, [1]),0,0);
          morphs[13] = dom.createMorphAt(element3,3,3);
          morphs[14] = dom.createMorphAt(element3,5,5);
          morphs[15] = dom.createMorphAt(element3,7,7);
          morphs[16] = dom.createMorphAt(dom.childAt(element4, [1]),0,0);
          morphs[17] = dom.createMorphAt(element4,3,3);
          morphs[18] = dom.createMorphAt(element4,5,5);
          morphs[19] = dom.createMorphAt(element4,7,7);
          morphs[20] = dom.createMorphAt(dom.childAt(element5, [1]),0,0);
          morphs[21] = dom.createMorphAt(element5,3,3);
          morphs[22] = dom.createMorphAt(element5,5,5);
          morphs[23] = dom.createMorphAt(element5,7,7);
          morphs[24] = dom.createMorphAt(dom.childAt(element6, [1]),0,0);
          morphs[25] = dom.createMorphAt(element6,3,3);
          morphs[26] = dom.createMorphAt(element6,5,5);
          morphs[27] = dom.createMorphAt(element6,7,7);
          morphs[28] = dom.createMorphAt(dom.childAt(element7, [1]),0,0);
          morphs[29] = dom.createMorphAt(element7,3,3);
          morphs[30] = dom.createMorphAt(element7,5,5);
          morphs[31] = dom.createMorphAt(element7,7,7);
          morphs[32] = dom.createMorphAt(dom.childAt(element8, [1]),0,0);
          morphs[33] = dom.createMorphAt(element8,3,3);
          morphs[34] = dom.createMorphAt(element8,5,5);
          morphs[35] = dom.createMorphAt(element8,7,7);
          return morphs;
        },
        statements: [
          ["inline","t",["form.label.lifestyle.smoking"],[],["loc",[null,[103,13],[103,49]]]],
          ["block","radio-button",[],["value","0","groupValue",["subexpr","@mut",[["get","model.smoking",["loc",[null,[104,47],[104,60]]]]],[],[]],"class","pure-radio"],0,null,["loc",[null,[104,10],[106,27]]]],
          ["block","radio-button",[],["value","1","groupValue",["subexpr","@mut",[["get","model.smoking",["loc",[null,[108,47],[108,60]]]]],[],[]],"class","pure-radio"],1,null,["loc",[null,[108,10],[110,27]]]],
          ["block","radio-button",[],["value","2","groupValue",["subexpr","@mut",[["get","model.smoking",["loc",[null,[112,47],[112,60]]]]],[],[]],"class","pure-radio"],2,null,["loc",[null,[112,10],[114,27]]]],
          ["inline","t",["form.label.lifestyle.alcohol"],[],["loc",[null,[118,13],[118,49]]]],
          ["block","radio-button",[],["value","0","groupValue",["subexpr","@mut",[["get","model.alcohol",["loc",[null,[119,47],[119,60]]]]],[],[]],"class","pure-radio"],3,null,["loc",[null,[119,10],[121,27]]]],
          ["block","radio-button",[],["value","1","groupValue",["subexpr","@mut",[["get","model.alcohol",["loc",[null,[123,47],[123,60]]]]],[],[]],"class","pure-radio"],4,null,["loc",[null,[123,10],[125,27]]]],
          ["block","radio-button",[],["value","2","groupValue",["subexpr","@mut",[["get","model.alcohol",["loc",[null,[127,47],[127,60]]]]],[],[]],"class","pure-radio"],5,null,["loc",[null,[127,10],[129,27]]]],
          ["inline","t",["form.label.lifestyle.caffeine"],[],["loc",[null,[133,13],[133,50]]]],
          ["block","radio-button",[],["value","0","groupValue",["subexpr","@mut",[["get","model.caffeine",["loc",[null,[134,47],[134,61]]]]],[],[]],"class","pure-radio"],6,null,["loc",[null,[134,10],[136,27]]]],
          ["block","radio-button",[],["value","1","groupValue",["subexpr","@mut",[["get","model.caffeine",["loc",[null,[138,47],[138,61]]]]],[],[]],"class","pure-radio"],7,null,["loc",[null,[138,10],[140,27]]]],
          ["block","radio-button",[],["value","2","groupValue",["subexpr","@mut",[["get","model.caffeine",["loc",[null,[142,47],[142,61]]]]],[],[]],"class","pure-radio"],8,null,["loc",[null,[142,10],[144,27]]]],
          ["inline","t",["form.label.lifestyle.motivation"],[],["loc",[null,[148,13],[148,52]]]],
          ["block","radio-button",[],["value","0","groupValue",["subexpr","@mut",[["get","model.motivation",["loc",[null,[149,47],[149,63]]]]],[],[]],"class","pure-radio"],9,null,["loc",[null,[149,10],[151,27]]]],
          ["block","radio-button",[],["value","1","groupValue",["subexpr","@mut",[["get","model.motivation",["loc",[null,[153,47],[153,63]]]]],[],[]],"class","pure-radio"],10,null,["loc",[null,[153,10],[155,27]]]],
          ["block","radio-button",[],["value","2","groupValue",["subexpr","@mut",[["get","model.motivation",["loc",[null,[157,47],[157,63]]]]],[],[]],"class","pure-radio"],11,null,["loc",[null,[157,10],[159,27]]]],
          ["inline","t",["form.label.lifestyle.control"],[],["loc",[null,[163,13],[163,49]]]],
          ["block","radio-button",[],["value","0","groupValue",["subexpr","@mut",[["get","model.control",["loc",[null,[164,47],[164,60]]]]],[],[]],"class","pure-radio"],12,null,["loc",[null,[164,10],[166,27]]]],
          ["block","radio-button",[],["value","1","groupValue",["subexpr","@mut",[["get","model.control",["loc",[null,[168,47],[168,60]]]]],[],[]],"class","pure-radio"],13,null,["loc",[null,[168,10],[170,27]]]],
          ["block","radio-button",[],["value","2","groupValue",["subexpr","@mut",[["get","model.control",["loc",[null,[172,47],[172,60]]]]],[],[]],"class","pure-radio"],14,null,["loc",[null,[172,10],[174,27]]]],
          ["inline","t",["form.label.lifestyle.rest"],[],["loc",[null,[178,13],[178,46]]]],
          ["block","radio-button",[],["value","0","groupValue",["subexpr","@mut",[["get","model.rest",["loc",[null,[179,47],[179,57]]]]],[],[]],"class","pure-radio"],15,null,["loc",[null,[179,10],[181,27]]]],
          ["block","radio-button",[],["value","1","groupValue",["subexpr","@mut",[["get","model.rest",["loc",[null,[183,47],[183,57]]]]],[],[]],"class","pure-radio"],16,null,["loc",[null,[183,10],[185,27]]]],
          ["block","radio-button",[],["value","2","groupValue",["subexpr","@mut",[["get","model.rest",["loc",[null,[187,47],[187,57]]]]],[],[]],"class","pure-radio"],17,null,["loc",[null,[187,10],[189,27]]]],
          ["inline","t",["form.label.lifestyle.fitness"],[],["loc",[null,[193,13],[193,49]]]],
          ["block","radio-button",[],["value","0","groupValue",["subexpr","@mut",[["get","model.fitness",["loc",[null,[194,47],[194,60]]]]],[],[]],"class","pure-radio"],18,null,["loc",[null,[194,10],[196,27]]]],
          ["block","radio-button",[],["value","1","groupValue",["subexpr","@mut",[["get","model.fitness",["loc",[null,[198,47],[198,60]]]]],[],[]],"class","pure-radio"],19,null,["loc",[null,[198,10],[200,27]]]],
          ["block","radio-button",[],["value","2","groupValue",["subexpr","@mut",[["get","model.fitness",["loc",[null,[202,47],[202,60]]]]],[],[]],"class","pure-radio"],20,null,["loc",[null,[202,10],[204,27]]]],
          ["inline","t",["form.label.lifestyle.handedness"],[],["loc",[null,[208,13],[208,52]]]],
          ["block","radio-button",[],["value","0","groupValue",["subexpr","@mut",[["get","model.handedness",["loc",[null,[209,47],[209,63]]]]],[],[]],"class","pure-radio"],21,null,["loc",[null,[209,10],[211,27]]]],
          ["block","radio-button",[],["value","1","groupValue",["subexpr","@mut",[["get","model.handedness",["loc",[null,[213,47],[213,63]]]]],[],[]],"class","pure-radio"],22,null,["loc",[null,[213,10],[215,27]]]],
          ["block","radio-button",[],["value","2","groupValue",["subexpr","@mut",[["get","model.handedness",["loc",[null,[217,47],[217,63]]]]],[],[]],"class","pure-radio"],23,null,["loc",[null,[217,10],[219,27]]]],
          ["inline","t",["form.label.lifestyle.games"],[],["loc",[null,[223,13],[223,47]]]],
          ["block","radio-button",[],["value","0","groupValue",["subexpr","@mut",[["get","model.games",["loc",[null,[224,47],[224,58]]]]],[],[]],"class","pure-radio"],24,null,["loc",[null,[224,10],[226,27]]]],
          ["block","radio-button",[],["value","1","groupValue",["subexpr","@mut",[["get","model.games",["loc",[null,[228,47],[228,58]]]]],[],[]],"class","pure-radio"],25,null,["loc",[null,[228,10],[230,27]]]],
          ["block","radio-button",[],["value","2","groupValue",["subexpr","@mut",[["get","model.games",["loc",[null,[232,47],[232,58]]]]],[],[]],"class","pure-radio"],26,null,["loc",[null,[232,10],[234,27]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17, child18, child19, child20, child21, child22, child23, child24, child25, child26]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 243,
            "column": 0
          }
        },
        "moduleName": "test-client/pods/testing/lifestyle/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","text-center pad");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h1");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("form");
        dom.setAttribute(el2,"role","form");
        dom.setAttribute(el2,"class","pure-form pure-form-stacked pad");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","lifestyle-inputs");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        dom.setAttribute(el3,"id","js-lifestyle");
        dom.setAttribute(el3,"class","pure-button pure-button-primary");
        dom.setAttribute(el3,"type","submit");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element15 = dom.childAt(fragment, [1]);
        var element16 = dom.childAt(element15, [5]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(dom.childAt(element15, [1]),0,0);
        morphs[1] = dom.createMorphAt(dom.childAt(element15, [3]),0,0);
        morphs[2] = dom.createElementMorph(element16);
        morphs[3] = dom.createMorphAt(dom.childAt(element16, [1]),1,1);
        morphs[4] = dom.createMorphAt(dom.childAt(element16, [3]),0,0);
        return morphs;
      },
      statements: [
        ["inline","t",["title.lifestyle"],[],["loc",[null,[3,6],[3,29]]]],
        ["inline","t",["copy.lifestyle.instructions"],[],["loc",[null,[4,5],[4,40]]]],
        ["element","action",["submitLifestyle",["get","model",["loc",[null,[6,87],[6,92]]]]],["on","submit"],["loc",[null,[6,60],[6,106]]]],
        ["block","if",[["get","model.isMinor",["loc",[null,[8,12],[8,25]]]]],[],0,1,["loc",[null,[8,6],[237,13]]]],
        ["inline","t",["button.finished"],[],["loc",[null,[240,84],[240,107]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});