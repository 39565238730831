define('test-client/models/set', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var attr = DS['default'].attr;
  exports['default'] = DS['default'].Model.extend({
    effort: attr(),
    duration: attr(),
    participant: DS['default'].belongsTo('participant', { async: false })
  });

});