define('test-client/models/training', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    title: DS['default'].attr(),
    videoNumber: DS['default'].attr(),
    text: DS['default'].attr()
  });

});