define('test-client/models/participant', ['exports', 'ember-data', 'moment'], function (exports, DS, moment) {

  'use strict';

  var attr = DS['default'].attr;
  exports['default'] = DS['default'].Model.extend({
    participant_id: (function () {
      return this.get('id');
    }).property('id'),
    male: attr(),
    age: attr(),
    race: attr(),
    education: attr(),
    startedAt: attr(),
    totalTime: attr(),
    finishedAt: attr(),
    complete: attr(),
    attempts: attr(),
    subjectIdentifier: attr(),
    familyIdentifier: attr(),
    status: attr(),
    iq: attr(),
    error: attr(),
    decisionMakingIndex: attr(),
    adjustedIqMother: attr(),
    adjustedIqFather: attr(),
    adjustedIqMean: attr(),
    progression: attr(),

    // lifestyle data
    motivation: attr(),
    school: attr(),
    control: attr(),
    rest: attr(),
    fitness: attr(),
    handedness: attr(),
    games: attr(),
    smoking: attr(),
    alcohol: attr(),
    caffeine: attr(),

    // the localization setting used for the test
    language: attr(),

    project: DS['default'].belongsTo('project', { async: false }),
    responses: DS['default'].hasMany('response', { async: false }),

    date: (function () {
      var started = this.get('startedAt');
      if (started) {
        return moment['default'](started).format('MMM DD, YY [at] hh:mma');
      }
    }).property('startedAt'),
    gender: (function () {
      var male = this.get('male');
      if (male === true) {
        return 'Male';
      } else if (male === false) {
        return 'Female';
      }
    }).property('male'),
    isMinor: (function () {
      return this.get('age') < 18;
    }).property('age'),
    maxAttemptsReached: (function () {
      return this.get('attempts') >= 2;
    }).property('attempts')
  });

});