define('test-client/pods/site/account/settings/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    storage: Ember['default'].inject.service(),
    beforeModel: function beforeModel() {
      if (!this.storage.get('user.isApiManager')) {
        return this.transitionTo('site.account');
      }
    },
    model: function model() {
      return Ember['default'].RSVP.hash({
        keys: this.store.findAll('key')
      });
    },
    actions: {
      generateKey: function generateKey() {
        var _this = this;

        this.store.createRecord('key', {
          description: this.get('controller.description')
        }).save().then(function () {
          _this.set('controller.description');
        });
      }
    }
  });

});