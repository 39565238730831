define('test-client/pods/site/account/project/participants/detail/route', ['exports', 'ember', 'ic-ajax', 'test-client/config/environment'], function (exports, Ember, ajax, config) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    d3Loaded: false,
    beforeModel: function beforeModel() {
      // we load this here to avoid IE8 having to deal with it
      if (!this.d3Loaded) {
        return Ember['default'].RSVP.all([Ember['default'].$.getScript('/assets/d3.min.js'), Ember['default'].$.getScript('/assets/c3.min.js')]);
      }
    },
    model: function model(params) {
      this.d3Loaded = true;
      return new Ember['default'].RSVP.hash({
        participant: this.store.findRecord('participant', params.participant_id, {
          reload: true,
          adapterOptions: {
            query: {
              experimental: ''
            }
          }
        }),
        user: this.storage.get('user')
      });
    },
    actions: {
      resetParticipant: function resetParticipant() {
        var _this = this;

        if (confirm('Are you sure?')) {
          var participant = this.get('controller.model.participant');

          ajax['default'](config['default'].host + '/' + config['default'].namespace + '/participants/' + participant.id + '/reset', {
            type: 'PUT',
            headers: {
              'Authorization': 'Token ' + this.get('storage.token')
            }
          }).then(function (reset) {
            _this.store.push(reset);
            _this.refresh();
          });
        }
      }
    }
  });

});