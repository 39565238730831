define('test-client/models/choice', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var attr = DS['default'].attr;
  exports['default'] = DS['default'].Model.extend({
    file: attr(),
    next: DS['default'].belongsTo('item', { async: false }),
    item: DS['default'].belongsTo('item', { async: false })
  });

});