define('test-client/adapters/response', ['exports', 'test-client/adapters/application'], function (exports, Adapter) {

  'use strict';

  exports['default'] = Adapter['default'].extend({
    createRecord: function createRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var url = this.buildURL(type.modelName, null, snapshot, 'createRecord');

      serializer.serializeIntoHash(data, type, snapshot, { includeId: true });

      return this.ajax(url + '/' + data.data.id, "PUT", { data: data });
    }
  });

});