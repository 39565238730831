define('test-client/pods/site/account/route', ['exports', 'ember', 'test-client/config/environment', 'ic-ajax'], function (exports, Ember, config, ajax) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    storage: Ember['default'].inject.service(),
    beforeModel: function beforeModel(transition) {
      if (!this.storage.get('token') && transition.targetName !== 'site.signin') {
        this.controllerFor('site.signin').set('nextTransition', transition);
        return this.transitionTo('site.signin');
      }
      if (!this.storage.get('legal') && transition.targetName !== 'site.account.nda') {
        return this.transitionTo('site.account.legal');
      }
    },
    model: function model(params, transition) {
      this.session.set('scheme', 'token');
      var id = this.storage.get('userID');
      if (!id) {
        this.controllerFor('site.signin').set('nextTransition', transition);
        return this.transitionTo('site.signin');
      }
      return this.store.find('identity', id);
    },
    afterModel: function afterModel(model) {
      // this is important to make sure the localStorage version of user is up-to-date after sign-in
      this.set('storage.user', model);

      Raven.setUserContext({
        id: model.id,
        name: model.get('firstName') + ' ' + model.getWithDefault('lastName', '').slice(0, 1).trim()
      });

      if (!model.get('ndaAccepted')) {
        this.transitionTo('site.account.nda');
      }
    },
    actions: {
      transitionTo: function transitionTo(route) {
        this.transitionTo(route);
      },
      signDownload: function signDownload(project) {
        ajax['default'](config['default'].host + '/' + config['default'].namespace + '/projects/' + project.id + '/sign-export', {
          headers: {
            'Authorization': 'Token ' + this.get('storage.token')
          },
          type: 'POST',
          contentType: 'application/vnd.api+json',
          dataType: 'json'
        }).then(function (result) {
          window.location.href = '' + result.data;
        })['catch'](function () {
          window.location.href = window.location.href;
        });
      }
    }
  });

});