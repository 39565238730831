define('test-client/pods/testing/controller', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    queryParams: ['lang'],
    i18n: Ember['default'].inject.service(),
    lang: Ember['default'].computed.alias('i18n.locale')
  });

});