define('test-client/pods/testing/instructions/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model() {
      var project = this.modelFor('testing').get('project');
      return this.store.peekAll('instruction').filterBy('project', project);
    }
  });

});