define('test-client/pods/404/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model() {
      Raven.captureMessage('404, not found', {
        level: 'info'
      });
    }
  });

});